import { BrowserModule, Title }  from '@angular/platform-browser';
import { NgModule ,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { SubmitComponent } from './submit/submit.component';
import { InviteComponent } from './invite/invite.component';
import {emailSettingComponent} from './email-setting/email-setting.component';
import { HttpClientModule,HttpClient} from '@angular/common/http';
import { JhiEventManager } from 'ng-jhipster';
import { ClipboardModule } from '@angular/cdk/clipboard'
import * as moment from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { HttpModule} from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { PublicRefferalComponent } from './public-refferal/public-refferal.component';
// import { ModalModule } from "ngx-bootstrap";
import { ModalModule } from 'ngx-bootstrap/modal';
import { UserRefferalComponent } from './user-refferal/user-refferal.component';
import { InviteZipCodeComponent } from './invite-zip-code/invite-zip-code.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FaqComponent } from './faq/faq.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AppService } from './app.service';
import {MatTabsModule} from '@angular/material/tabs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentsComponent } from './contents/contents.component';
import { ReferComponent } from './refer/refer.component';
import { SafePipe } from './pipe/safe.pipe';
import {MatFormFieldModule} from '@angular/material/form-field'; 
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AboutComponent,
    ContactComponent,
    BlogComponent,
    SubmitComponent,
    InviteComponent,
    emailSettingComponent,
    PublicRefferalComponent,
    UserRefferalComponent,
    InviteZipCodeComponent,
    TermConditionComponent,
    FaqComponent,
    PrivacyPolicyComponent,
    ContentsComponent,
    ReferComponent,
    SafePipe
  ],
  imports: [
    MatTabsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HttpModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(),
    MatSelectCountryModule.forRoot('en'),
    ModalModule,
    ClipboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule
  

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [HttpModule,Title,AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
