import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { JhiParseLinks } from 'ng-jhipster';
import { ICampaignZipCode } from '../shared/model/campaign-zip-code.model';
import { InviteService } from '../invite.service';
import { ToastrService } from 'ngx-toastr'


@Component({
  selector: 'app-invite-zip-code',
  templateUrl: './invite-zip-code.component.html',
  styleUrls: ['./invite-zip-code.component.scss']
})
export class InviteZipCodeComponent implements OnInit {
  campaignZipCodes: ICampaignZipCode[];
  currentSearch: string;
  routeData: any;
  links: any;
  totalItems: any;
  queryCount: any;
  itemsPerPage: any;
  page: any;
  predicate: any;
  previousPage: any;
  reverse: any;
  loading: boolean;

  constructor(
      private campaignZipCodeService: InviteService,
      private parseLinks: JhiParseLinks,
      private activatedRoute: ActivatedRoute,
      private toastrService: ToastrService
  ) {
      this.itemsPerPage = 1000;
      this.routeData = this.activatedRoute.data.subscribe(data => {
        //   this.page = data.pagingParams.page;
        //   this.previousPage =  data.pagingParams.page;
        //   this.reverse = 'asc';
        //   this.predicate = 'countryName';
          this.page = 1;
          this.previousPage = 1;
          this.reverse = 'desc';
          this.predicate = 'id';
      });
      this.currentSearch = '';
  }

  loadAll() {
      this.loading = true;
      const _obj = {
          page: this.page - 1,
          size: this.itemsPerPage,
          sort: this.sort()
      };
      if (this.currentSearch) {
          _obj['query'] = this.currentSearch;
      }
      this.campaignZipCodeService
          .getCountryCurrency({
              page: this.page - 1,
              size: this.itemsPerPage,
              sort: this.sort()
          })
          .subscribe(
              (res: HttpResponse<ICampaignZipCode[]>) => this.paginateCampaignZipCodes(res.body, res.headers),
              (res: HttpErrorResponse) => this.onError(res.message)
          );
  }

  loadPage(page: number) {
      if (page !== this.previousPage) {
          this.previousPage = page;
      }
  }

  transition() {
      this.loadAll();
  }

  clear() {
      this.page = 1;
      this.currentSearch = '';
      this.loadAll();
  }

  search(query) {
      if (!query) {
          return this.clear();
      }
      this.page = 1;
      this.currentSearch = query;
      this.loadAll();
  }

  ngOnInit() {
      this.loadAll();
  }

  sort() {
      const result = [this.predicate + ',' + (this.reverse ? 'desc' : 'desc')];
      if (this.predicate !== 'id') {
          result.push('id');
      }
      return result;
  }

  private paginateCampaignZipCodes(data: ICampaignZipCode[], headers: HttpHeaders) {
      this.links = this.parseLinks.parse(headers.get('link'));
      this.totalItems = parseInt(headers.get('X-Total-Count'), 10);
      this.queryCount = this.totalItems;
      this.campaignZipCodes = data;
      this.loading = false;
  }

  private onError(errorMessage: string) {
      this.loading = false;
      this.toastrService.error(errorMessage);
  }

  sizechange(size: any) {
      this.itemsPerPage = size;
      this.loading = true;
      this.clear();
  }
}

