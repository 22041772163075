
<div class="public-referral-cover">
    <h1 style="color: #000;text-align: center;margin-top: 50px;">{{ 'muferralGatewayApp.campaignUser.contact' | translate }}</h1>
    <div class="row justify-content-center card-body headng">
        <div class="col-md-12 col-xs-12 invite-top-text">{{ 'muferralGatewayApp.campaignUser.contactHeading' | translate }}</div>
    </div>
  <div class="col-md-4 custom-box-width custom-box-width-success  jh-card cms-main" >
  
  
  
      <div class="card col-md-12 public-ref-card-body">
          <div class="row justify-content-center card-body">
              <div class="col-md-12 col-xs-12">
                  <form name="contactForm" role="form" novalidate (ngSubmit)="saveContact()" #contactForm="ngForm" >
  
                      <div class="public-ref-form-cover">
                         <div class="form-group row" style="justify-content: center;">
                              <input type="text" class="form-control col-md-9 col-xs-12" 
                              placeholder="{{ 'muferralGatewayApp.campaignUser.email_address' | translate }}" name="email" id="email"
                              [(ngModel)]="email" required email="true" autocomplete="off"/>
  
                          </div>
  
                          <div class="form-group row"  style="justify-content: center;">
                             <select class="form-control col-md-9 col-xs-12" id="select" [(ngModel)]="select" 
                             name="select" placeholder="What's this about?" required>
                                  <!-- <option  value=""  disabled="true" [selected]="true">What's this about?</option> -->
                                  <option value="Feedback">{{ 'muferralGatewayApp.campaignUser.feedback' | translate }}</option>
                                  <option value="Request a call">{{ 'muferralGatewayApp.campaignUser.request' | translate }}</option>
                                  <option value="Support">{{ 'muferralGatewayApp.campaignUser.support' | translate }}</option>
                                  <option value="Investment Opportunities">{{ 'muferralGatewayApp.campaignUser.investmentOpportunities' | translate }}</option>
                                  <option value="Marketing and Influencing">{{ 'muferralGatewayApp.campaignUser.marketingAndInfluencing' | translate }}</option>
                                  <option value="Join our team">{{ 'muferralGatewayApp.campaignUser.join_our_team' | translate }}</option>
                                  <option value="Other">{{ 'muferralGatewayApp.campaignUser.other' | translate }}</option>
                                </select>
                          </div>

                         
                          <div class="form-group row"  style="justify-content: center;">
                            <textarea  class="form-control col-md-9 col-xs-12"  name="description"  [(ngModel)]="description"  placeholder="{{ 'muferralGatewayApp.campaignUser.comment' | translate }}" [maxlength]="maxChars" required></textarea>
                          
                          </div>
                          
                          <div class="row">
                              <div class="col-12 text-center public-submit-btn-cover">
                                  <button type="submit"  [disabled]="contactForm.form.invalid" id="save-entity"
                                      class="btn btn-primary public-add-btn">
                                      <!-- <fa-icon [icon]="'save'"></fa-icon>&nbsp; -->
                                      <span>{{ 'muferralGatewayApp.campaignUser.submit' | translate }}</span>
                                  </button>
                                </div>
                          </div>
                      </div>
  
                  </form>

              </div>
          </div>
      </div>
  </div>