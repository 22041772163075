import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import {TranslateService} from '@ngx-translate/core';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
currentYear:any;
dataChng:any;
languages: any[];
isSaving: boolean;
email: string;
confirmEmail: string;
loading: boolean;
success: boolean;
name: any;
captcha: string;
condition1: boolean;
condition2: boolean;
condition3: boolean;
senderId: string;
userName: string;
isUserNameAvailable: Boolean = false;
langKeyArray: string[] = [];
selectedLanguage: string;
lngSltData:string;
dataForHeaderLand:any;
step:any='step1';
lng:any;
selectedLng:any;
  constructor(private appService: AppService,public translate: TranslateService) {
    this.dataForHeaderLand=localStorage.getItem('key');
if(localStorage.getItem('key') === null) 
{
var dtaValue=localStorage.getItem('firstLang');
if(dtaValue==='fr')
{
  this.lngSltData="French";
}
else if(dtaValue === 'en' || dtaValue === 'en-GB' || dtaValue === 'en-ZA' || dtaValue === 'en-NZ' || dtaValue === 'en-IN' || dtaValue === 'en-CA' || dtaValue === 'en-US' || dtaValue === 'en-AU')
{
  this.lngSltData="English";
}
else if(dtaValue === 'es')
{
  this.lngSltData="Spanish";
}
else if(dtaValue === 'zh')
{
  this.lngSltData="Chinese";
}
else if(dtaValue === 'it')
{
  this.lngSltData="Italian";
}
else if(dtaValue === 'ja')
{
  this.lngSltData="Japanese";
}
else if(dtaValue === 'de')
{
  this.lngSltData="German";
}
else if(dtaValue === 'hi')
{
  this.lngSltData="Hindi";
}
else if(dtaValue === 'ko')
{
  this.lngSltData="Korean";
}
else if(dtaValue === 'ru')
{
  this.lngSltData="Russian";
}
else if(dtaValue === 'ar')
{
  this.lngSltData="Arabic";
}
else if(dtaValue === 'pt')
{
  this.lngSltData="Portuguese";
}
this.dataForHeaderLand=this.lngSltData;
}
    translate.addLangs([ 'Arabic','Chinese','English','French','German','Hindi','Italian','Japanese','Korean','Portuguese','Russian','Spanish']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/ar|zh|fr|de|hi|it|ja|ko|pt|ru|es/) ? browserLang : this.dataForHeaderLand);
    this.selectedLng=translate.currentLang;

   }

  ngOnInit(): void {
    this.lng=localStorage.getItem('key');
    this.currentYear = new Date().getFullYear();
    this.appService.stringSubject.subscribe(
      data => {
        this.dataChng = data;
        this.selectedLng=data;
      }
    );
  }

}
