import { Moment } from 'moment';

export interface ICampaignUser {
    id?: string;
    firstName?: string;
    lastName?: string;
    zipCode?: string;
    email?: string;
    createdBy?: string;
    createdDate?: Moment;
    lastModifiedBy?: string;
    lastModifiedDate?: Moment;
    active?: boolean;
    deleted?: boolean;
    knowInPerson?: boolean;
    conceptAndBenefitExplained?: boolean;
    ageOverEighteen?: boolean;
    termsAndConditionsRead?: boolean;
    countryId?: string;
    phoneId?: string;
    countryCode?: string;
}

export class CampaignUser implements ICampaignUser {
    constructor(
        public id?: string,
        public firstName?: string,
        public lastName?: string,
        public zipCode?: string,
        public email?: string,
        public createdBy?: string,
        public createdDate?: Moment,
        public lastModifiedBy?: string,
        public lastModifiedDate?: Moment,
        public active?: boolean,
        public deleted?: boolean,
        public knowInPerson?: boolean,
        public conceptAndBenefitExplained?: boolean,
        public ageOverEighteen?: boolean,
        public termsAndConditionsRead?: boolean,
        public countryId?: string,
        public phoneId?: string
    ) {
        this.active = this.active || false;
        this.deleted = this.deleted || false;
        this.knowInPerson = this.knowInPerson || false;
        this.conceptAndBenefitExplained = this.conceptAndBenefitExplained || false;
        this.ageOverEighteen = this.ageOverEighteen || false;
        this.termsAndConditionsRead = this.termsAndConditionsRead || false;
    }
}
