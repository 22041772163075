
<footer class="page-footer font-small black p-0 fixed-bottom">
    <div class="container">
<div class="footer-copyright ftr">© {{currentYear}}
        <a> Muferral</a>
    </div>
    <div class="footer-term ftre">
        <a href="#/private-policy">{{ 'muferralGatewayApp.campaignUser.privacyPolicy' | translate }}</a> | 
        <a href="#/term-condition">{{ 'muferralGatewayApp.campaignUser.termCondition' | translate }}</a>
       <!-- <span> <a href="https://www.facebook.com/Muferral-113175240430968"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/facebook+logo.png"></a> -->
        <span> <a href="{{ 'muferralGatewayApp.campaignUser.facebook' | translate }}"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/facebook+logo.png"></a>
            <!-- <a href="https://www.linkedin.com/"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/linkedin+logo.png"></a> -->
        <!-- <a href="https://twitter.com/Muferral1"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/twitter+logo.png"></a>
        <a href="https://www.youtube.com/channel/UC2Nt97Xu-5xePyz-4wvf12g"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/youtube+logo.png"></a> -->
        <a href="{{ 'muferralGatewayApp.campaignUser.telegram' | translate }}"  target="_blank"><img src="assets/images/telegram.png"></a>
        <!-- <a href="{{ 'muferralGatewayApp.campaignUser.twitter' | translate }}"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/twitter+logo.png"></a> -->
        <a href="{{ 'muferralGatewayApp.campaignUser.youtube' | translate }}"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/youtube+logo.png"></a>
        <a href="{{ 'muferralGatewayApp.campaignUser.oneMore' | translate}}"  target="_blank"><img src="https://muferral-media-dev.s3.amazonaws.com/logos/camp.png" *ngIf="selectedLng === 'Russian'"></a>
    </span>
    </div>
</div>
</footer>
