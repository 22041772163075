import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InviteService } from '../invite.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contents',
  templateUrl: './contents.component.html',
  styleUrls: ['./contents.component.scss']
})
export class ContentsComponent implements OnInit {
  title: string;
  key: string;
  loading: boolean;
  data: any;
  constructor(private route: ActivatedRoute,private _service: InviteService,private sanitizer: DomSanitizer) {
    this.route.paramMap.subscribe(params => {
      this.title = params.get('title');
      this.key = params.get('key');
      if (params.get('key') === null && localStorage.getItem('key') === null) {
       this.key='en';    
    }else{
        let e = localStorage.getItem('key');
        if (e === 'French') {
            this.key = 'fr';
        } else if (e === 'English') {
            this.key = 'en';
        } else if (e === 'Spanish') {
            this.key = 'es';
        } else if (e === 'Chinese') {
            this.key = 'zh';
        } else if (e === 'Italian') {
            this.key = 'it';
        } else if (e === 'Japanese') {
            this.key = 'ja';
        } else if (e === 'German') {
            this.key = 'de';
        } else if (e === 'Hindi') {
            this.key = 'hi';
        } else if (e === 'Korean') {
            this.key = 'ko';
        } else if (e === 'Russian') {
            this.key = 'ru';
        }
        else if(e === 'Arabic')
  {
    this.key="ar";
  }
  else if(e === 'Portuguese')
  {
    this.key="pt";
  }
    }
  });
  this.getdata();
   }
  
  ngOnInit(): void {
  
  }
getdata(){
  console.log("title is",this.title);
  console.log("key is",this.key);
  this.loading = true;
  this._service.getContents(this.title, this.key).subscribe(
    (resp: any) => {
      this.loading = false;
        this.data = this.sanitizer.bypassSecurityTrustHtml(resp.body.contentDescription);      
    }
  );
}
}
