import { Component, OnInit } from '@angular/core';
import {InviteService} from '../invite.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '../app.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  email:string;
  select:string;
  description:string;
  loading: boolean;
  maxChars = 3000;
  language_type:any;
  departmentList: string[] = [
    'Feedback',
    'Request a call',
    'Support',
    'Join our team',
    'Complain Others'
  ];

  constructor(private inviteService: InviteService,private toastr: ToastrService, private appService: AppService) { }

  ngOnInit(): void {
    this.appService.stringSubject.subscribe(
      data => {
       this.language_type = data ? data : localStorage.getItem('key');
      
      }
    );
    this.language_type = localStorage.getItem('key') 
    this.select="Feedback";
    
  }
  saveContact()
  {
    
    this.loading = true;
    const request = {
      email_address: this.email,
      subject: this.select,
      description:this.description,
      languageType:(this.language_type).toUpperCase()
  };
    this.inviteService.contactus(request).subscribe(
      
      resp => {
          if (resp) {
              this.toastr.success('Message Sent');
              this.email='';
              this.select='Feedback';
              this.description='';
              this.loading = false;
          }
      },
      err => {
          this.loading = false;
          this.toastr.error(err.error.errorMessage);
      }
  );

  }

}
