<div class="invite-content-section common-card" >
    <div class="container" >
        <div class="row">
            <div class="col-md-12  custom-box-width invite-full-width" *ngIf="!success">
                <div class="card">
                    <!-- dir="auto" -->
                    <!-- <div class="row">
                        <div class="col-md-12 col-xs-12 mb-3">
                            <div class="view">
                                <img src="../../../../content/images/logo.png" style="height:100px;width:300px;margin: 0 auto;"
                                     class="card-img-top" alt="photo">
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="row justify-content-center card-title">
                        <div class="col-md-12 col-xs-12 m-t-10"></div>
                    </div> -->
                    <div class="row justify-content-center card-body" >
                        <div class="col-md-12 col-xs-12 invite-top-text" >{{ 'muferralGatewayApp.campaignUser.invite_one' | translate }}</div>
                    </div>
                    <div class="row justify-content-center card-body">
                        <div class="col-md-6 col-lg-7 col-xs-12 invitetabcover">
                            <!-- <iframe width="100%" height="310" src="https://www.youtube.com/embed/tgbNymZ7vqY" style="
                            width: 100%;vertical-align: middle;">
                            </iframe> -->
                            <!-- <mat-tab-group [(selectedIndex)]="TabIndex">
                                <mat-tab id="ink-top-fix" label="Muferral concept" (click)="video('a')">   <iframe width="100%" height="310" src="https://www.youtube.com/embed/tgbNymZ7vqY" style="
                                    width: 100%;vertical-align: middle;" >
                                    </iframe></mat-tab>
                                <mat-tab id="ink-top-fix1" label="Muferral Reward Program" (click)="video('b')">  <iframe width="100%" height="310" src="https://www.youtube.com/embed/tgbNymZ7vqY?start=172" style="
                                    width: 100%;vertical-align: middle;" >
                                    </iframe> </mat-tab>
                                <mat-tab id="ink-top-fix2" label="Campaign" (click)="video('c')">   <iframe width="100%" height="310" src="https://www.youtube.com/embed/tgbNymZ7vqY?start=212" style="
                                    width: 100%;vertical-align: middle;" >
                                    </iframe> </mat-tab>
                              </mat-tab-group> -->
                              <!-- <my-tabs>
                                <my-tab id="ink-top-fix" [tabTitle]="'Tab 1'">
                                  Tab 1 content
                                </my-tab>
                                <my-tab id="ink-top-fix" tabTitle="Tab 2">
                                  Tab 2 content
                                </my-tab>
                              </my-tabs> -->
                              <ngb-tabset #ctdTabset="ngbTabset">
                                <!-- <ngb-tab title="Muferral Concept ►" id="muferral_concept"> -->
                                    <ngb-tab title="{{ 'muferralGatewayApp.campaignUser.videoTav.muferral_concept' | translate }} ►" id="muferral_concept">
                                  <ng-template ngbTabContent>
                                    <iframe width="100%" height="310" [src]="yt1 | safe" style="
                                    width: 100%;vertical-align: middle;" allowfullscreen>
                                    </iframe>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab  title="{{ 'muferralGatewayApp.campaignUser.videoTav.reward_program' | translate }} ►" id="muferral_reward_program">
                                  <ng-template class="tabsec" ngbTabContent>
                                    <iframe width="100%" height="310" [src]="yt2 | safe" style="
                                    width: 100%;vertical-align: middle;" allowfullscreen >
                                    </iframe>
                                  </ng-template>
                                </ngb-tab>
                                <ngb-tab title="{{ 'muferralGatewayApp.campaignUser.videoTav.the_campaign' | translate }} ►" id="campaign">
                                    <ng-template ngbTabContent>
                                        <iframe width="100%" height="310" [src]="yt3 | safe" style="
                                        width: 100%;vertical-align: middle;" allowfullscreen>
                                        </iframe> 
                                    </ng-template>
                                  </ngb-tab>
                              </ngb-tabset>
                             
                            <div class="row justify-content-center card-body">
                                   
                                <div class="col-md-12 col-xs-12 invite-top-text">
                                    <img src="../../assets/images/Muferral Referral.svg" class="card-img-top" alt="photo">
                                    {{ 'muferralGatewayApp.campaignUser.slogan' | translate }}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6  col-lg-5 col-xs-12">
                            <form class="form" role="form" (ngSubmit)="invite()" #loginForm="ngForm">
                                
                                <div class="form-group">
                                        <label *ngIf="!isUserNameAvailable" class="username-label" for="username" > {{ 'muferralGatewayApp.campaignUser.invite_two' | translate }}</label>
                                        <label *ngIf="isUserNameAvailable" class="username-label" for="username">{{ 'muferralGatewayApp.campaignUser.invite_four' | translate }}<b>{{userName}}</b>{{ 'muferralGatewayApp.campaignUser.invite_three' | translate }}</label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon11"><i class="fa fa-envelope-o"></i></span>
                                        </div>
                                        <input type="text" class="form-control no-border-left" name="username" id="username"
                                               placeholder="{{ 'muferralGatewayApp.campaignUser.email_address' | translate }}" [(ngModel)]="email" maxlength=254 email="true" [pattern]="'[a-zA-Z0-9+.-_]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'"
                                               #passwordInput="ngModel" required autocomplete="off">
                                        <div class="col-md-12 m-t-5 no-padding"
                                             *ngIf="passwordInput.dirty && passwordInput.invalid">
                                            <small class="form-text text-danger" *ngIf="passwordInput?.errors.required"
                                                   jhiTranslate="global.messages.validate.email.required">
                                                Your email is required.
                                            </small>
                                            <small class="form-text text-danger" *ngIf="passwordInput?.errors.pattern"
                                                   jhiTranslate="global.messages.validate.email.invalid">
                                                Your email is invalid.
                                            </small>
                                            <small class="form-text text-danger" *ngIf="passwordInput?.errors.minlength"
                                                   jhiTranslate="global.messages.validate.email.minlength">
                                                Your email is required to be at least 5 characters.
                                            </small>
                                            <small class="form-text text-danger" *ngIf="passwordInput?.errors.maxlength"
                                                   jhiTranslate="global.messages.validate.email.maxlength">
                                                Your email cannot be longer than 100 characters.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-envelope-o"></i></span>
                                        </div>
                                        <input type="text" class="form-control no-border-left" name="senderEmail" id="senderEmail"
                                               placeholder="{{ 'muferralGatewayApp.campaignUser.confirm_email_address' | translate }}" [(ngModel)]="confirmEmail" required
                                               autocomplete="off" #confirmPasswordInput="ngModel" email="true" [pattern]="'[a-zA-Z0-9+.-_]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'">
                                        <div class="col-md-12 m-t-5 no-padding"
                                             *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.invalid">
                                            <small class="form-text text-danger" *ngIf="confirmPasswordInput?.errors.required"
                                                   jhiTranslate="global.messages.validate.email.required">
                                                Your email is required.
                                            </small>
                                            <small class="form-text text-danger" *ngIf="confirmPasswordInput?.errors.pattern"
                                                   jhiTranslate="global.messages.validate.email.invalid">
                                                Your email is invalid.
                                            </small>
                                            <small class="form-text text-danger" *ngIf="confirmPasswordInput?.errors.minlength"
                                                   jhiTranslate="global.messages.validate.email.minlength">
                                                Your email is required to be at least 5 characters.
                                            </small>
                                            <small class="form-text text-danger" *ngIf="confirmPasswordInput?.errors.maxlength"
                                                   jhiTranslate="global.messages.validate.email.maxlength">
                                                Your email cannot be longer than 100 characters.
                                            </small>
                                        </div>
                                        <div class="col-md-12 m-t-5 no-padding"
                                             *ngIf="confirmPasswordInput.dirty && confirmPasswordInput.model !== passwordInput.model">
                                            <small class="form-text text-danger"
                                                   *ngIf="confirmPasswordInput.model !== passwordInput.model"
                                                   jhiTranslate="global.messages.validate.confirmpassword.notmatched">
                                                   Email did not match.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group" *ngIf="!isUserNameAvailable">
                                    <input class="form-check-input" [checked]="true" [(ngModel)]="condition1" type="checkbox"
                                        name="condition1" id="field_condition1" required />
                                    <label class="form-check-label" for="field_condition1">
                                        <span>{{ 'muferralGatewayApp.campaignUser.i_understand' | translate }}</span> 
                                    </label>
                                </div>
            
                                <!----> <!-- Add ngIf conditions -->
                                <div class="form-group" *ngIf="isUserNameAvailable">
                                    <input class="form-check-input" [checked]="true" [(ngModel)]="condition2" type="checkbox"
                                        name="condition2" id="field_condition2" required />
                                    <label class="form-check-label" for="field_condition2">
                                        <!-- <span>{{'invitecampaign.checkbox2-1' | translate }} <b>{{userName}}</b>’s {{'invitecampaign.checkbox2-2' | translate }}</span> -->
                                        <span>{{ 'muferralGatewayApp.campaignUser.step_two_i understand' | translate }}<b>{{userName}}</b>{{ 'muferralGatewayApp.campaignUser.step_two_i_understand' | translate }}</span>
                                    </label>
                                </div>
                                <div class="form-group" *ngIf="isUserNameAvailable">
                                    <input class="form-check-input" [checked]="true" [(ngModel)]="condition3" type="checkbox"
                                        name="condition3" id="field_condition3" required />
                                    <label class="form-check-label" for="field_condition3">
                                        <!-- <span>{{'invitecampaign.checkbox3' | translate }} <b>{{userName}}</b>.</span> -->
                                        <span>{{ 'muferralGatewayApp.campaignUser.step_two_i_understand1' | translate }}<b>{{userName}}</b>{{ 'muferralGatewayApp.campaignUser.step_two_i_understand2' | translate }}</span>
                                    </label>
                                </div>
            
                                <div class="form-group ">
                                    <input type="text" class="form-control capt-input" name="captcha" id="captcha"
                                        placeholder="{{ 'muferralGatewayApp.campaignUser.enter_code' | translate }}" [(ngModel)]="captcha" maxlength=6 #captchaInput="ngModel" required
                                        autocomplete="off">
                                    <div class="capt">
                                        <span type="text" id="mainCaptcha" #mainCaptcha class="text-center"></span>
                                    </div>
                                    <i class="fa fa-refresh refres-icon text-center" id="refresh" type="button"
                                        style="color: #1F73B7;top: 10px;cursor: pointer;" (click)="generateCaptcha()"
                                        value="Refresh"></i>
                                </div>
            
                                <div class="form-group  m-t-10">
                                    <button [disabled]="loginForm.form.invalid" type="submit"
                                        class="btn btn-primary col-md-12 col-xs-12 invite-btn-black">{{ 'muferralGatewayApp.campaignUser.start_invitation_process' | translate }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    
                </div>
            </div>


            <!-- <div class="col-md-12 custom-box-width invite-full-width custom-box-width-success " *ngIf="success">
                <div class="row justify-content-center card-title">
                        
                        <div class="col-md-10 col-xs-12">
                            <div class="view">
                                <img src="../../../../content/images/logo.png" style="height:100px;width:300px;margin: 0 auto;"
                                     class="card-img-top" alt="photo">
                            </div>
                        </div>
                    </div>
                <div class="card">
                    
                    <div class="row justify-content-center card-body">
                        <div class="col-md-10 col-xs-12 no-padding-success">
                            <h3 class="text-center">THANKS FOR INVITING NEW USER</h3>
                            <p class="text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin in sollicitudin ante. Sed ipsum tortor, tempus sed ante eget, tristique imperdiet velit. </p>
                            <div class="col-sm-12 text-center success-invite-text"><a mdbWavesEffect>Invite New User</a></div>
                            <hr>
                            <h5>Instructions</h5>
                            <h5>Step 1:</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum tortor eu ante sagittis rutrum.
                                Suspendisse rhoncus feugiat urna, et tristique libero posuere sed.</p>
                            <h5>Step 2:</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum tortor eu ante sagittis rutrum.
                                Suspendisse rhoncus feugiat urna, et tristique libero posuere sed.</p>
                            <h5>Step 3:</h5>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed bibendum tortor eu ante sagittis rutrum.
                                Suspendisse rhoncus feugiat urna, et tristique libero posuere sed.</p>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="custom-box-width custom-box-width-success " *ngIf="success" style="margin-top: 56px">
                <!-- <div class="row justify-content-center card-title">
            
                    <div class="col-md-10 col-xs-12">
                        <div class="view">
                            <img src="../../../../content/images/logo.png" style="height:100px;width:300px;margin: 0 auto;"
                                class="card-img-top" alt="photo">
                        </div>
                    </div>
                </div> -->
                <div class="card" *ngIf="!isUserNameAvailable">
            
                    <div class="row justify-content-center card-body">
                        <div class="col-md-10 col-xs-12 no-padding-success">
                            <p class="text-center" style="font-size: 22px;"><strong>{{ 'muferralGatewayApp.campaignUser.successful' | translate }}</strong></p>
                            <p class="text-center"><strong>{{ 'muferralGatewayApp.campaignUser.step_one_invitation' | translate }}</strong></p>
                            <br>
                            <p class="text-center" style="font-size: 18px;"><strong>{{ 'muferralGatewayApp.campaignUser.step_what' | translate }}</strong></p>
                            <p class="text-center" >{{ 'muferralGatewayApp.campaignUser.step_once' | translate }}</p>
                        </div>
                    </div>
                </div>
            
                <div class="card" *ngIf="isUserNameAvailable">
            
                    <div class="row justify-content-center card-body">
                        <div class="col-md-10 col-xs-12 no-padding-success">
                            <p class="text-center" style="font-size: 22px;"><strong>{{ 'muferralGatewayApp.campaignUser.successful' | translate }}</strong></p>
                            <p class="text-center"><strong>{{ 'muferralGatewayApp.campaignUser.step_two_invitation1' | translate }}{{userName}}{{ 'muferralGatewayApp.campaignUser.step_two_invitation' | translate }}</strong></p>
                            <br>
                            <p class="text-center" style="font-size: 18px;"><strong>{{ 'muferralGatewayApp.campaignUser.step_what' | translate }}</strong></p>
                            <p class="text-center" >{{ 'muferralGatewayApp.campaignUser.step_once' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="loading-indicator" *ngIf="loading">
                <mat-progress-spinner mode="indeterminate" [diameter]="50" color="primary"></mat-progress-spinner>
            </div>
        </div>
    </div>
</div>