import { Component, OnInit} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {filter, map, mergeMap} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'muferral-gateway';
  titleData:any;
 
  // constructor(){
  //   var x = navigator.language;
  //   localStorage.setItem('firstLang', x);
  // }
  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,) {
      var x = navigator?.language;
      if(x !== null && x !== undefined && x !== ''){
        localStorage.setItem('firstLang', x);
      }
      else{
        localStorage.setItem('firstLang', 'en');
      }
     
    // this.router.events.pipe(
    //   filter((event) => event instanceof NavigationEnd),
    //   map(() => this.activatedRoute),
    //   map((route: any) => {
    //     while (route.firstChild) route = route.firstChild;
    //     return route;
    //   }),
    //   filter((route) => route.outlet === 'primary'),
    //   mergeMap((route: any) => route.data)).subscribe((event) => {
    //     this.titleService.setTitle(event['title']);
    //     console.log('Page Title*', event['title']);
    //     this.titleData= event['title'];
    //   });
  }
  
  
   
  ngOnInit(): void {
    console.log("change is");
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function(){
    // var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    // s1.async=true;
    // s1.src='https://embed.tawk.to/5eda4a7a9e5f6944228ff6c8/default';
    // s1.charset='UTF-8';
    // s1.setAttribute('crossorigin','*');
    // s0.parentNode.insertBefore(s1,s0);
    // })();
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
(function()

{ var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; s1.async=true; s1.src='https://embed.tawk.to/5e56b866a89cda5a18881827/default'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); }
)();
  }
  
  
}

