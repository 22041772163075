<div class="about common-main">
	<div class="container">
<h1 style="color: #000;text-align: center;margin-bottom: 50px">{{ 'muferralGatewayApp.campaignUser.abouta' | translate }}</h1>
<div class="row">
	<!-- <div class="col-md-6 col-xs-12">
		<h5>{{ 'muferralGatewayApp.campaignUser.about1' | translate }}</h5>
		<p>{{ 'muferralGatewayApp.campaignUser.about2' | translate }}</p>
		<ul>
			<li>{{ 'muferralGatewayApp.campaignUser.about3' | translate }}</li>
			<li>{{ 'muferralGatewayApp.campaignUser.about4' | translate }}</li>
		</ul>
		<p>{{ 'muferralGatewayApp.campaignUser.about5' | translate }}</p>
		<p>{{ 'muferralGatewayApp.campaignUser.about6' | translate }}</p>
		
	</div>
	<div class="col-md-6 col-xs-12" >
		<img src="../../assets/images/abouta.jpg">
	</div>
	<div class="col-md-12 col-xs-12" style="margin-top:30px;">
		<p>{{ 'muferralGatewayApp.campaignUser.about7' | translate }}</p>
		<p>{{ 'muferralGatewayApp.campaignUser.about8' | translate }}</p>
		<p>{{ 'muferralGatewayApp.campaignUser.about9' | translate }}</p>
		<p>{{ 'muferralGatewayApp.campaignUser.about10' | translate }}</p>
		</div> -->
		<div [innerHTML]="dataBlog"></div>
</div>
</div>
</div>
