import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders} from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
// import {environment} from '../environments/environment'
import { ICampaignZipCode } from './shared/model/campaign-zip-code.model';
import { createRequestOption } from './shared';

const httpOptions = {
  headers: new HttpHeaders({
  'Content-Type':  'application/json',
  'Authorization': 'my-auth-token'
 })
};

@Injectable({
  providedIn: 'root'
})
export class InviteService {
    // SERVER_API_URL="https://qa.muferral.com/";
    SERVER_API_URL="https://stage.muferral.com/";
    // SERVER_API_URL="https://dev.muferral.com/";
  

  constructor(private http: HttpClient) {}

  findLangauges(): Observable<any> {

      const url = this.SERVER_API_URL + 'api/v1/system-languages';
      return this.http.get<any>(url,{ observe: 'response' });
  }
  findLangData(data,key): Observable<any> {

    const url = this.SERVER_API_URL + `api/v1/contents/${data}/${key}`;
    return this.http.get<any>(url,{ observe: 'response' });
}
  
 
  inviteUser(data: any): Observable<any> {

      const url = this.SERVER_API_URL + 'api/v1/invite-users';
      return this.http.post<any>(url,data, { observe: 'response' });
  }

  getCountries() {
    const url = this.SERVER_API_URL + 'api/v1/countries?size=500&sort=name%2Casc';
      return this.http.get<any>(url, { observe: 'response' });
  }

  getZipcode(req?: any): Observable<any> {
      const url = this.SERVER_API_URL + 'api/v1/campaign-zip-codes';
      const options = createRequestOption(req);
      return this.http.get<ICampaignZipCode[]>(url, { params: options, observe: 'response' }).pipe();
  }
  getCountryCurrency(req?: any): Observable<any> {
    const url = this.SERVER_API_URL + 'api/v1/commissionList';
    const options = createRequestOption(req);
    return this.http.get<ICampaignZipCode[]>(url, { params: options, observe: 'response' }).pipe();
}
  createUser(data: any): Observable<any> {
      const url = this.SERVER_API_URL + 'api/v1/campaign-users';
      return this.http.post<any>(url, data, { observe: 'response' });
  }

  contactus(data: any): Observable<any> {
    const url = this.SERVER_API_URL + 'api/v1/contactUs';
    return this.http.post<any>(url, data, { observe: 'response' });
}
  createItemService(data: any, isItem: boolean): Observable<any> {
    const url = this.SERVER_API_URL + 'campaign-users/${isItem}/item-service-informations';
      return this.http.post<any>(url, data, { observe: 'response' });
  }

  uploadProfileImage(data: any, campaignUserId: string, isId: string) {
       const url = this.SERVER_API_URL + 'campaign-users/${campaignUserId}/itemservices/${isId}/item-service-media';
      return this.http
          .post<any>(url, data, {
              observe: 'response'
          })
          .pipe();
  }
  getContents(title?: string, key?: string): Observable<any> {
    const url = this.SERVER_API_URL + `api/v1/contents/${title}/${key}`;
    return this.http.get<any>(url, { observe: 'response' });
    // return this.http.get<any[]>(`api/v1/contents/${title}/${key}`, { observe: 'response' }).pipe();
}
inviterEmail(key?:any): Observable<any> {
  const url = this.SERVER_API_URL + `api/v1/invite-user-details/${key}`;
  return this.http.get<any>(url, { observe: 'response' });
  // return this.http.get<any[]>(`api/v1/contents/${title}/${key}`, { observe: 'response' }).pipe();
}

emailSettingID(userId?:any): Observable<any> {
  const url = this.SERVER_API_URL + `api/v1/campaign-user-email-notification/${userId}`;
  return this.http.get<any>(url, { observe: 'response' });
}

emailSettingKey(userId?:any, key?:any): Observable<any> {
  const url = this.SERVER_API_URL + `api/v1/campaign-user-email-notification/${userId}/${key}`;
  return this.http.get<any>(url,{ observe: 'response' });
}
}

