import { Component, OnInit, Pipe, ViewChild  } from '@angular/core';
import { AppService } from '../app.service';
import { InviteService } from '../invite.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { CampaignUser } from '../shared/model/campaign-user.model';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.scss']
})
export class emailSettingComponent implements OnInit {
  @ViewChild('basicModal') basicModal: any;
  mySubjectVal:any;
  dataBlog:any;
  loading: boolean;
  isChecked:boolean;
  // email:boolean =false;
  userId:any;
  success: boolean;
  campaignUser: any;
  constructor(private activatedRoute: ActivatedRoute,private appService: AppService, private toastr: ToastrService ,private _service: InviteService,private sanitizer: DomSanitizer) {
    this.campaignUser = new CampaignUser();
    this.activatedRoute.params.subscribe((params: any) => {
      this.userId = params.id;

  });
    this.changeLng(localStorage.getItem('key'));

   }

  ngOnInit(): void {
    // var x = navigator.language;
    // console.log("language is",x);
    if(this.userId){
      this.emailSettingID();
    }
   
    this.appService.stringSubject.subscribe(
      data => {
        console.log("change call");
        this.mySubjectVal = data;
      this.changeLng(data);
      }
    );
  }
  changeLng(e){
    let lng:any;
  if(e==='French')
  {
  lng='fr';
  }
  else if(e === 'English')
  {
    lng='en';
  }
  else if(e === 'Spanish')
  {
    lng='es';
  }
  else if(e === 'Chinese')
  {
    lng='zh';
  }
  else if(e === 'Italian')
  {
    lng='it';
  }
  else if(e === 'Japanese')
  {
    lng='ja';
  }
  else if(e === 'German')
  {
    lng='de';
  }
  else if(e === 'Hindi')
  {
    lng='hi';
  }
  else if(e === 'Korean')
  {
    lng='ko';
  }
  else if(e === 'Russian')
  {
    lng='ru';
  }
  else if(e === 'Arabic')
  {
    lng='ar';
  }
  else if(e === 'Portuguese')
  {
    lng='pt';
  }
  // this.loading = true;
  // this._service.findLangData('blog',lng).subscribe(
  //   (resp: any) => {
  //     this.loading = false;
  //       this.dataBlog = this.sanitizer.bypassSecurityTrustHtml(resp.body.contentDescription);      
  //   }
  // );
  }


emailSettingID() {
  this.loading = true;
  this._service.emailSettingID(this.userId).subscribe(
      resp => {
          if (resp) {
            this.loading = true;
            this.campaignUser.email = resp.body.campaignUserEmailNotification;
             this.success = true;
              this.loading = false;
          }
      },
      err => {
          // this.success = false;
          this.loading = false;
          this.toastr.error(err.error.errorMessage);
      }
  );
}
 

emailSetting() {
  
  this.loading = true;
  this._service.emailSettingKey(this.userId, this.campaignUser.email).subscribe(
      resp => {
          if (resp) {
              // this.toastr.success('Invitation Sent');
              this.campaignUser.email = resp.body.campaignUserEmailNotification;
              this.success = true;
              this.loading = false;
          }
      },
      err => {
          this.success = false;
          this.loading = false;
          this.toastr.error(err.error.errorMessage);
      }
  );
}
}
