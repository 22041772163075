import { Component, OnInit, ViewChild } from '@angular/core';
import { CampaignUser } from '../shared/model/campaign-user.model';
import { InviteService } from '../invite.service';
import { ToastrService } from 'ngx-toastr'
import { forkJoin } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UploadAwsService } from '../shared/services/upload-aws.service';
const  SERVER_API_URL="https://dev.muferral.com/";
import {TranslateService} from '@ngx-translate/core';
import { ThrowStmt } from '@angular/compiler';
import {Title} from "@angular/platform-browser";
import { AppService } from '../app.service';

@Component({
  selector: 'app-user-refferal',
  templateUrl: './user-refferal.component.html',
  styleUrls: ['./user-refferal.component.scss']
})
export class UserRefferalComponent implements OnInit {
  campaignUser: any;
  @ViewChild('basicModal') basicModal: any;
  success: boolean;
  countries: Array<any>;
  zipCodes: Array<any>;
  loading: boolean;
  servicesItemsArray: Array<any>;
  files: any;
  base64textString: string;
  isSaving: boolean;
  selectedZipCode: string;
  profileImageFile: any;
  userData: any;
  reffererId: any;
  reffererName: any;
  fileArray: any;
  s3Bucket: string;
  uniqueString:string;
  serverApiUrl: string;
  firstName: string;
  lastName: string;
  encryptedFirstName: string;
  encryptedUserId: string;
  encryptedFirstAndLastName: string;

  reffererEmail: string;
  messageNotifNoLogin:any;
  inviteUrl:any;
  maxChars = 3000;
  language_type:string;
  language_key:any;
  localLanguage:any;
  registerationStatus:any;
  translateData:any;

  constructor(
    private inviteService: InviteService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private awsService: UploadAwsService,
    public translate: TranslateService,
    private titleService:Title,
    private appService: AppService,
    private route: Router
  ) {
    this.activatedRoute.params.subscribe(data => {
      const decodedData = atob(data.referrerData).split(',');
      console.log('this.decodeedData', decodedData[0]);
      console.log('this.decodeedData', decodedData[1]);
      console.log('this.decodeedData', decodedData[2]);

      this.reffererId = decodedData[0];
      this.reffererName = decodedData[1];
      this.reffererEmail = decodedData[2];
      // this.reffererId = atob(data.referrerId);
      // this.reffererName = atob(data.referrerName);
      // this.reffererEmail = atob(data.referrerEmail);
      this.activatedRoute.params.subscribe((params: any) => {
        this.uniqueString = params.referrerData;

    });
   

  });
  this.campaignUser = new CampaignUser();
  this.campaignUser.email = this.reffererEmail;
  this.success = false;
  this.servicesItemsArray = [];
  this.fileArray = [];
  this.translate.instant('muferralGatewayApp.campaignUser.hi_friend',{inviteUrl:this.inviteUrl})
  this.serverApiUrl = SERVER_API_URL;
  // this.titleService.setTitle('Muferral | '+ this.reffererName +'’s invitation');
}

ngOnInit() {
  this.appService.stringSubject.subscribe(
    data => {
     this.language_key = data ? data : localStorage.getItem('key');
    if( this.language_key === "English"){
        this.language_type = 'en';
    }else if (this.language_key === "French"){
        this.language_type = 'fr';
    }
    else if (this.language_key === "Spanish"){
        this.language_type = 'es';
    }else if (this.language_key === "Chinese"){
        this.language_type = 'zh';
    }else if (this.language_key === "Italian"){
        this.language_type = 'it';
    }else if (this.language_key === "Japanese"){
        this.language_type = 'ja';
    }else if (this.language_key === "German"){
        this.language_type = 'de';
    }else if (this.language_key === "Hindi"){
        this.language_type = 'hi';
    }else if (this.language_key === "Korean"){
        this.language_type = 'ko';
    }else if (this.language_key === "Russian"){
        this.language_type = 'ru';
    }else if (this.language_key === "Arabic"){
        this.language_type = 'ar';
    }else if (this.language_key === "Portuguese"){
        this.language_type = 'pt';
    }else{
        this.language_type = 'en';
    }
    }
  );

  if(!this.language_type){
    this.localLanguage = localStorage.getItem('key'); 
    if( this.localLanguage === "English"){
        this.language_type = 'en';
    }else if (this.localLanguage === "French"){
        this.language_type = 'fr';
    }
    else if (this.localLanguage === "Spanish"){
        this.language_type = 'es';
    }else if (this.localLanguage === "Chinese"){
        this.language_type = 'zh';
    }else if (this.localLanguage === "Italian"){
        this.language_type = 'it';
    }else if (this.localLanguage === "Japanese"){
        this.language_type = 'ja';
    }else if (this.localLanguage === "German"){
        this.language_type = 'de';
    }else if (this.localLanguage === "Hindi"){
        this.language_type = 'hi';
    }else if (this.localLanguage === "Korean"){
        this.language_type = 'ko';
    }else if (this.localLanguage === "Russian"){
        this.language_type = 'ru';
    }else if (this.localLanguage === "Arabic"){
        this.language_type = 'ar';
    }else if (this.localLanguage === "Portuguese"){
        this.language_type = 'pt';
    }else{
        this.language_type = 'en';
    }
  }
 
  if(this.uniqueString){
    this.inviteEmail();
  }
  const urlPart = (this.s3Bucket = window.location.href.split('/')[2]);
  if (urlPart === '34.232.47.53') {
      this.s3Bucket = 'muferral-media-dev';
  } else if (urlPart === '35.175.155.251') {
      this.s3Bucket = 'muferral-media-qa';
  } else if (urlPart === '3.211.190.21') {
      this.s3Bucket = 'muferral-media-stage';
  } else {
      this.s3Bucket = 'muferral-media-dev';
  }
  this.loading = true;
  const bs1 = this.inviteService.getCountries();
  const bs2 = this.inviteService.getZipcode();

  forkJoin(bs1, bs2).subscribe(resp => {
      this.countries = resp[0].body;
      this.zipCodes = resp[1].body;
      this.loading = false;
  });
  this.LoadTextNotififBar();
}

public LoadTextNotififBar() {
  this.translate.get('muferralGatewayApp.campaignUser.hi_friend').subscribe( (text: string) => {
    this.messageNotifNoLogin = text  + this.inviteUrl;
  });
}
previousState() {}

keyPress(event: any) {
  const pattern = /[0-9\+\-\ ]/;
  const inputChar = String.fromCharCode(event.charCode);
  if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
  }
}

addItemService() {
  if (this.profileImageFile) {
      this.servicesItemsArray.push({
          title: this.campaignUser.title,
          description: this.campaignUser.description,
          type: this.campaignUser.type,
          image: this.base64textString
      });
      this.campaignUser.title = '';
      this.campaignUser.description = '';
      this.campaignUser.type = '';
      this.campaignUser.image = '';
      this.profileImageFile = null;
  } else {
      this.toastrService.info('Please Add Image');
  }
}

readFile(evt) {
  const files = evt.target.files;
  const file = files[0];
  this.profileImageFile = files[0];
  this.fileArray.push(files[0]);
  if (files && file) {
      const reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(file);
  }
}

_handleReaderLoaded(readerEvt) {
  const binaryString = readerEvt.target.result;
  this.base64textString = 'data:image/jpeg;base64,' + btoa(binaryString);
}

saveUser() {
  this.loading = true;
  const itemAndService = [];

  this.servicesItemsArray.map((item, index) => {
      itemAndService.push({
          description: item.description,
          title: item.title,
          item: item.type === 'item' ? true : false
      });
  });
  if(itemAndService.length > 0){
  const requestUser = {
      ageOverEighteen: true,
      conceptAndBenefitExplained: true,
      countryId: this.campaignUser.country.id,
      declaration: true,
      email: this.campaignUser.email,
      firstName: this.campaignUser.firstName,
      knowInPerson: false,
      lastName: this.campaignUser.lastName,
      phoneId: this.campaignUser.countryCode&&this.campaignUser.phone?'+'+this.campaignUser.countryCode+this.campaignUser.phone:this.campaignUser.phone,
      termsAndConditionsRead: true,
      zipCode: this.campaignUser.zipCode,
      reffererId: this.reffererId,
      itemAndServiceDTOS: itemAndService,
      refferalUniqueKey:this.uniqueString,
      languageType:this.language_type,
      phoneCode: this.campaignUser.countryCode,
      phone: this.campaignUser.phone
      
  };
  if(this.campaignUser.phone && !this.campaignUser.countryCode){
    this.toastrService.error('Please select county code.');
    this.loading = false;
    return
}
  if(requestUser.phoneId ==='')
  {
      delete requestUser.phoneId;
  }
  this.inviteService.createUser(requestUser).subscribe(
      resp => {
          if (resp.body) {
              this.firstName = resp.body.firstName;
              this.lastName = resp.body.lastName;
              this.inviteUrl= resp.body.inviteUrl,
              this.encryptedUserId = btoa(resp.body.id);
              this.encryptedFirstAndLastName = btoa(this.firstName + ' ' + this.lastName);

              resp.body.itemAndServiceDTOS.forEach((element, index) => {
                  const fileName = this.fileArray[index].name;
                  const path = `Campaign/${element.item ? 'ITEM' : 'SERVICE'}/${element.itemId}/${fileName}`;
                  this.awsService
                      .uploadFile(this.fileArray[index], path, this.s3Bucket)
                      .then(res => {
                          if (index === resp.body.itemAndServiceDTOS.length - 1) {
                              // this.toastrService.success('Invitation Process Success');
                              this.campaignUser = new CampaignUser();
                              this.servicesItemsArray = [];
                              this.success = true;
                              this.isSaving = false;
                              this.loading = false;
                          }
                      })
                      .catch(err => {
                          this.toastrService.error('File Upload Failed');
                          this.loading = false;
                      });
              });
          }
      },
      err => {
          this.toastrService.error(err.error.errorMessage);
          this.isSaving = false;
          this.loading = false;
      }
  );
    } else{
        this.loading = false;
        this.toastrService.error(this.changetranslate());
    }
  
}
copy(messageNotifNoLogin) {

  
  this.translate.get('muferralGatewayApp.campaignUser.hi_friend').subscribe( (text: string) => {
  
  this.messageNotifNoLogin = text + this.inviteUrl;
});

return `${this.messageNotifNoLogin}`;
}

changetranslate(){
    this.translate.get('muferralGatewayApp.campaignUser.submitmsg').subscribe( (text: string) => {
    this.translateData = text ;
         });
        return `${this.translateData}`;
    }


inviteEmail() {
  this.loading = true;
  this.inviteService.inviterEmail(this.uniqueString).subscribe(
      resp => {
          if (resp.body) {
              // this.toastr.success('Invitation Sent');
              // this.success = true;
              this.campaignUser.email = resp.body.email
              this.reffererName = resp.body.userFullName;
              this.reffererId = resp.body.userId
              this.registerationStatus = resp.body.registerationStatus;
            if (this.reffererName !== undefined) {
            // this.isUserNameAvailable = true;
            this.titleService.setTitle('Muferral | '+ this.reffererName +'’s invitation');
           }
              this.loading = false;
          }else {
            this.route.navigate(['/invite']);
          }
      },
      err => {
          this.success = false;
          this.loading = false;
          this.toastrService.error(err.error.errorMessage);
      }
  );
}
}
