import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { forkJoin } from "rxjs";
import { saveAs } from "file-saver";
import * as JSZip from 'jszip';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-refer',
  templateUrl: './refer.component.html',
  styleUrls: ['./refer.component.scss']
})
export class ReferComponent {
  serviceData:any;
  // data = [
  //   '../../assets/image/movement1.PNG',
  //   '../assets/image/Screenshot_6.png'
  // ];
  data = [
    'https://muferral-legal-docs-dev.s3.amazonaws.com/user/5ef3680d8b87bc0001b0eb08/LEGAL_DOCUMENTS/image_2_1593010196415.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20200716T135420Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3600&X-Amz-Credential=AKIAJM2K44VN262XBBOA%2F20200716%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=f3fc58e18f95a10418e2bea46b975546fa04f9a78d600f565a951d8e30f6d449'
  ];
// data:any=[];
  getRequests = [];

  constructor(private _http: HttpClient) {}
  findLangData(): Observable<any> {

    const url = `https://dev.muferral.com/api/v1/documents?date=2020-06-24`;
    return this._http.get<any>(url,{ observe: 'response' });
}
download(){
  // this.dtaSer();
  // console.log("final data is",this.data);
this.downloadDta();
}
dtaSer(){

  this.findLangData()
  .subscribe(res=>{
    console.log("Responce data is",res);
    this.serviceData=res.body.images;
    this.dataFilter(res.body.images);
  }
     
  );
}
dataFilter(dta:any){
  console.log("Filter Data is",dta);
  for(let i=0;i < dta.length;i++)
  {
   this.data.push(dta[i].preSignedUrl);
  }
 this.downloadDta();
}
  downloadDta() {
    this.createGetRequets(this.data);
    forkJoin(...this.getRequests)
     .subscribe((res) => {
       console.log("resonce is",res);
      const zip = new JSZip();

      res.forEach((f, i) => {
        zip.file(`image${i}.png`, f);
      });

      /* With file saver */
      zip
        .generateAsync({ type: 'blob' })
        .then(blob => saveAs(blob, 'ts.zip'));

      /* Without file saver */
      // zip
      //   .generateAsync({ type: 'blob' })
      //   .then(blob => {
      //     const a: any = document.createElement('a');
      //     document.body.appendChild(a);

      //     a.style = 'display: none';
      //     const url = window.URL.createObjectURL(blob);
      //     a.href = url;
      //     a.download = 'image.zip';
      //     a.click();
      //     window.URL.revokeObjectURL(url);
      //   });
     });
  }

  private createGetRequets(data: string[]) {
    data.forEach(url => this.getRequests.push(this._http.get(url, { responseType: 'blob' })));
    console.log("data is",data);
  }
}
