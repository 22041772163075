import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { lang } from 'moment';
import { InviteService } from '../invite.service';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  mySubjectVal:any;
  dataLng:any;
  loading: boolean;
  constructor(private appService: AppService,private _service: InviteService,private sanitizer: DomSanitizer) {
    this.changeLng(localStorage.getItem('key'));
   }

  ngOnInit(): void {
    this.appService.stringSubject.subscribe(
      data => {
        this.mySubjectVal = data;
      this.changeLng(data);
      }
    );
  }
changeLng(e){
  let lng:any;
if(e==='French')
{
lng='fr';
}
else if(e === 'English')
{
  lng='en';
}
else if(e === 'Spanish')
{
  lng='es';
}
else if(e === 'Chinese')
{
  lng='zh';
}
else if(e === 'Italian')
{
  lng='it';
}
else if(e === 'Japanese')
{
  lng='ja';
}
else if(e === 'German')
{
  lng='de';
}
else if(e === 'Hindi')
{
  lng='hi';
}
else if(e === 'Korean')
{
  lng='ko';
}
else if(e === 'Russian')
{
  lng='ru';
}
else if(e === 'Arabic')
  {
    lng="ar";
  }
  else if(e === 'Portuguese')
  {
    lng="pt";
  }
console.log("language conversion is",lng);
this.loading = true;
this._service.findLangData('faq',lng).subscribe(
  (resp: any) => {
    this.loading = false;
      this.dataLng = this.sanitizer.bypassSecurityTrustHtml(resp.body.contentDescription);
     
  }
);
}
}
