import { Component, OnInit } from '@angular/core';
import { InviteService } from '../invite.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '../app.service';
// import {Country} from '@angular-material-extensions/select-country';
// import { Country, Country } from '../shared/model/country.model';

declare var $: any;
interface Country {
  name: string;
  alpha2Code: string;
  
}
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  readonly: boolean;
  languages: any[];
  isSaving: boolean;
  email: string;
  confirmEmail: string;
  loading: boolean;
  success: boolean;
  name: any;
  captcha: string;
  condition1: boolean;
  condition2: boolean;
  condition3: boolean;
  senderId: string;
  userName: string;
  isUserNameAvailable: Boolean = false;
  langKeyArray: string[] = [];
  predefinedCountries=[];
  selectedLanguage: string;
  lngSltData: string;
  dataForHeaderLand: any;
  step: any = 'step1';
  langList:any[];
  Country: { name: string; alpha2Code: string;}[];
  defaultValue: { name: string; alpha2Code: string;  };
  CountryFlag: string;
  currentLangChange: string;
  // defaultValue:any;
 
  constructor(private appService: AppService, private _service: InviteService, private toastr: ToastrService, public translate: TranslateService) {
    this.dataForHeaderLand = localStorage.getItem('key') ? localStorage.getItem('key') : localStorage.getItem('firstLang');
    if (localStorage.getItem('key') === null) {
      var dtaValue = localStorage.getItem('firstLang');
      if (dtaValue === 'fr') {
        this.lngSltData = "French";
        this.CountryFlag = 'fr'
      }
      else if (dtaValue === 'en' || dtaValue === 'en-GB'|| dtaValue === 'en-ZA' || dtaValue === 'en-NZ' || dtaValue === 'en-IN' || dtaValue === 'en-CA' || dtaValue === 'en-US' || dtaValue === 'en-us' || dtaValue === 'en-AU') {
        console.log("enter in english");
        this.lngSltData = "English";
        this.CountryFlag = 'us'
      }
      else if (dtaValue === 'es') {
        this.lngSltData = "Spanish";
        this.CountryFlag = 'es'
      }
      else if (dtaValue === 'zh') {
        this.lngSltData = "Chinese";
        this.CountryFlag = 'cn'
      }
      else if (dtaValue === 'it') {
        this.lngSltData = "Italian";
        this.CountryFlag = 'it'
      }
      else if (dtaValue === 'ja') {
        this.lngSltData = "Japanese";
        this.CountryFlag = 'jp'
      }
      else if (dtaValue === 'de') {
        this.lngSltData = "German";
        this.CountryFlag = 'de'
      }
      else if (dtaValue === 'hi') {
        this.lngSltData = "Hindi";
        this.CountryFlag = 'in'
      }
      else if (dtaValue === 'ko') {
        this.lngSltData = "Korean";
        this.CountryFlag = 'ko'
      }
      else if (dtaValue === 'ru') {
        this.lngSltData = "Russian";
        this.CountryFlag = 'ru'
      }
      else if (dtaValue === 'ar') {
        this.lngSltData = "Arabic";
        this.CountryFlag = 'sa'
      }
      else if (dtaValue === 'pt') {
        this.lngSltData = "Portuguese";
        this.CountryFlag = 'pt'
       
      }else{
        // this.lngSltData = "English";
        this.dataForHeaderLand = this.lngSltData ? this.lngSltData : this.dataForHeaderLand;
      }
      
    } else{
      // this.lngSltData = "English";
      this.dataForHeaderLand = this.lngSltData ? this.lngSltData : this.dataForHeaderLand;
    }
    
    // translate.addLangs([ 'French','English','Spanish','Chinese','Italian','Japanese','German','Hindi','Korean','Russian','Arabic','Portuguese']);
    //     const browserLang = translate.getBrowserLang();
    //     translate.use(browserLang.match(/fr|es|zh|it|ja|de|hi|ko|ru|ar|pt/) ? browserLang : this.dataForHeaderLand);


    translate.addLangs(['Arabic', 'Chinese', 'English', 'French', 'German', 'Hindi', 'Italian', 'Japanese', 'Korean', 'Portuguese', 'Russian', 'Spanish']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/ar|zh|fr|de|hi|it|ja|ko|pt|ru|es/) ? browserLang : this.dataForHeaderLand);
    console.log("current language is", translate.currentLang)
    // this.defaultValue = translate.currentLang
    let currentLang = translate.currentLang;
    if(!currentLang){
      currentLang = "English"
      localStorage.setItem('firstLang','en');
    }
    if(currentLang== 'Arabic'){
      this.currentLangChange= "Arabic"
      this.CountryFlag ='sa'
    }else if(currentLang== 'Chinese'){
      this.CountryFlag ='cn'
    }else if (currentLang === "English"  ||  currentLang === "en-US" )
    {
      this.currentLangChange= "English"
      this.CountryFlag ='us'
    }else if(currentLang== 'French'){
      this.currentLangChange= "French"
      this.CountryFlag ='fr'
    }else if(currentLang== 'German'){
      this.currentLangChange= "German"
      this.CountryFlag ='de'
    }else if(currentLang== 'Hindi'){
      this.currentLangChange= "Hindi"
      this.CountryFlag ='in'
    }else if(currentLang== 'Chinese'){
      this.currentLangChange= "Chinese"
      this.CountryFlag ='cn'
    }else if(currentLang== 'Italian'){
      this.currentLangChange= "Italian"
      this.CountryFlag ='it'
    }else if(currentLang== 'Japanese'){
      this.currentLangChange= "Japanese"
      this.CountryFlag ='jp'
    }else if(currentLang== 'Korean'){
      this.currentLangChange= "Korean"
      this.CountryFlag ='ko'
    }else if(currentLang== 'Russian'){
      this.currentLangChange= "Russian"
      this.CountryFlag ='ru'
    }else if(currentLang== 'Spanish'){
      this.currentLangChange= "Spanish"
      this.CountryFlag ='es'
    }else if(currentLang== 'Portuguese'){
      this.currentLangChange= "Portuguese"
      this.CountryFlag ='pt'
    }

    this.defaultValue = {
      name: this.currentLangChange ,
      alpha2Code: this.CountryFlag,
   };
  }
  changelanguage(key) {
    // localStorage.setItem('dta','abcd');
    localStorage.setItem('key', key);
    sessionStorage.setItem('lngKey', key);
    this.appService.passValue(key);
    this.translate.use(key)
  }
  onCountrySelected(event){
    localStorage.setItem('key', event.name);
    sessionStorage.setItem('lngKey', event.name);
    this.appService.passValue(event.name);
    this.translate.use(event.name);
  }

  keydown(event: any) {
    event.stopPropagation()
    event.preventDefault();
    // const keyCode = event.keyCode;

    // const excludedKeys = [8, 37, 39, 46];

    // if (!((keyCode >= 48 && keyCode <= 57) ||
    //   (keyCode >= 96 && keyCode <= 105) ||
    //   (excludedKeys.includes(keyCode)))) {
    //   event.preventDefault();
    // }
}
  ngOnInit(): void {
  //   $(document).ready(function () {
  //     $(document).click(function (event) {
  //         var clickover = $(event.target);
  //         var _opened = $(".navbar-collapse").hasClass("show");
  //         if (_opened === true && !clickover.hasClass("navbar-toggler")) {
  //             $(".navbar-toggler").click();
  //         }
  //     });
  // });

  $(document).on('click', function(event){
    var $clickedOn = $(event.target),
        $collapsableItems = $('.collapse'),
        isToggleButton = ($clickedOn.closest('.navbar-toggle').length == 1),
        isLink = ($clickedOn.closest('a').length == 1),
        isOutsideNavbar = ($clickedOn.parents('.navbar').length == 0);
  
    if( (!isToggleButton && isLink) || isOutsideNavbar ) {
      $collapsableItems.each(function(){
        $(this).collapse('hide');
      });
    }
  });

        // let hide = true;
        // $('body').on("click", function () {
        // if (hide) $('.navbar-collapse.in').removeClass('in');
        // hide = false;
        // });
        // $('navbarSupportedContent').on("click", function () {
        //   if (hide) $('.navbar-collapse.in').removeClass('in');
        //   hide = false;
        //   });
    this.getData();
    // this.langList = [{'imageSrc' :'assets/images/in.svg'}]
  //   this.defaultValue = {
  //     name: 'English',
  //     alpha2Code: 'eg',
  //  };
    this.Country= [
      {
        name:'Arabic',
        alpha2Code: 'sa',

      },
      {
        name:'Chinese',
        alpha2Code: 'cn',

      },
      {
        name: 'English',
        alpha2Code: 'us',
       
      },
      {
        name: 'French',
        alpha2Code: 'fr',
      },
      {
        name: 'German',
        alpha2Code: 'de',
      },
      {
        name: 'Hindi',
        alpha2Code: 'in'
        
      },
      {
        name: 'Italian',
        alpha2Code: 'it'
        
      },
      {
        name:'Japanese',
        alpha2Code: 'jp',

      },
      {
        name:'Korean',
        alpha2Code: 'kr',

      },
      {
        name:'Portuguese',
        alpha2Code: 'pt',

      },
      {
        name:'Russian',
        alpha2Code: 'ru',

      }, 
      {
        name: 'Spanish',
        alpha2Code: 'es',
       
      }
    ];
  }


  getData() {
    this.loading = true;
    this._service.findLangauges().subscribe(
      (resp: any) => {
        this.loading = false;
        this.languages = resp.body;
        for (const i in this.languages) {
          if (i !== null) {
            this.langKeyArray.push(this.languages[i]['langKey']);
          }
        }
      },
      err => {
        this.toastr.info('No Langauge Found');
        this.loading = false;
      }
    );
  }
  ToggleNavBar() {
    let element: HTMLElement = document.getElementsByClassName('navbar-toggler')[0] as HTMLElement;
    if (element.getAttribute('aria-expanded') == 'true') {
      element.click();
    }
  }
}
