import { Component, OnInit, Pipe  } from '@angular/core';
import { AppService } from '../app.service';
import { InviteService } from '../invite.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.scss']
})
export class TermConditionComponent implements OnInit {
  mySubjectVal:any;
  dataBlog:any;
  loading: boolean;
  constructor(private appService: AppService,private _service: InviteService,private sanitizer: DomSanitizer) {
    this.changeLng(localStorage.getItem('key'));
   }

  ngOnInit(): void {
    // var x = navigator.language;
    // console.log("language is",x);
    this.appService.stringSubject.subscribe(
      data => {
        this.mySubjectVal = data;
      this.changeLng(data);
      }
    );
  }
  changeLng(e){
    let lng:any;
  if(e==='French')
  {
  lng='fr';
  }
  else if(e === 'English')
  {
    lng='en';
  }
  else if(e === 'Spanish')
  {
    lng='es';
  }
  else if(e === 'Chinese')
  {
    lng='zh';
  }
  else if(e === 'Italian')
  {
    lng='it';
  }
  else if(e === 'Japanese')
  {
    lng='ja';
  }
  else if(e === 'German')
  {
    lng='de';
  }
  else if(e === 'Hindi')
  {
    lng='hi';
  }
  else if(e === 'Korean')
  {
    lng='ko';
  }
  else if(e === 'Russian')
  {
    lng='ru';
  }
  else if(e === 'Arabic')
  {
    lng="ar";
  }
  else if(e === 'Portuguese')
  {
    lng="pt";
  }
  this.loading = true;
  this._service.findLangData('terms',lng).subscribe(
    (resp: any) => {
      this.loading = false;
        this.dataBlog = this.sanitizer.bypassSecurityTrustHtml(resp.body.contentDescription);      
    }
  );
  }
}
