<!-- <div class="col-md-12 col-xs-12 mb-3">
    <div class="view">
        <img src="../../../../content/images/logo.png" style="height:100px;width:300px;margin: 0 auto;"
            class="card-img-top" alt="photo">
    </div>
</div> -->
<div class="public-referral-cover">
<div class="col-md-4 custom-box-width custom-box-width-success jh-card cms-main" *ngIf ="!registerationStatus || registerationStatus === null">
    <div class="card col-md-12 public-ref-card-body" *ngIf="!success">
        <div class="row">
            <div class="col-md-12">
                <h5 id="jhi-campaign-user-heading" class="public-ref-heading">
                    {{ 'muferralGatewayApp.campaignUser.step_two_form_title' | translate }}{{reffererName}}{{'muferralGatewayApp.campaignUser.step_two_form_title1' | translate }}</h5>
            </div>
        </div>
        <div class="row justify-content-center card-body">
            <div class="col-md-12 col-xs-12">
                <form name="campaignForm" role="form" novalidate (ngSubmit)="saveUser()" #campaignForm="ngForm">
                    
                    <div class="public-ref-form-cover">
                        <div class="form-group row">
                            <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_firstName">
                                <span >{{ 'muferralGatewayApp.campaignUser.firstName' | translate }}</span> <span
                                    style="color: red !important;">*</span>
                            </label>
                            <input type="text" class="form-control col-md-9 col-xs-12" name="firstName"
                                id="field_firstName" [(ngModel)]="campaignUser.firstName" required />

                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_lastName">
                                <span>{{ 'muferralGatewayApp.campaignUser.lastName' | translate }}</span> <span
                                style="color: red !important;">*</span>
                            </label>
                            <input type="text" class="form-control col-md-9 col-xs-12" name="lastName"
                                id="field_lastName" [(ngModel)]="campaignUser.lastName" required />
                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_lastName">
                                <span>{{ 'muferralGatewayApp.campaignUser.email' | translate }}</span> 
                                <span  style="color: red !important;">*</span>
                            </label>
                            <input type="text" class="form-control col-md-9 col-xs-12" name="email" id="email"
                                [(ngModel)]="campaignUser.email" required email="true" autocomplete="off"  />

                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_country">
                                <span>{{ 'muferralGatewayApp.campaignUser.country' | translate }}</span> <span
                                style="color: red !important;">*</span>
                            </label>
                            <select class="browser-default select-dropdown w-100 form-control col-md-9 col-xs-12"
                                id="field_country" name="country" [(ngModel)]="campaignUser.country">
                                <option
                                    [ngValue]="countryOption.id === campaignUser.country?.id ? campaignUser.country : countryOption"
                                    *ngFor="let countryOption of countries;">
                                    {{countryOption.name}}
                                </option>
                            </select>
                            <div class="col-sm-12 text-right public-view-zipcode" ><a
                                    routerLink="/commission-chart"
                                    target="_blank" mdbWavesEffect>{{ 'muferralGatewayApp.campaignUser.ziplist' | translate }}</a>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_zipCode">
                                <span>{{ 'muferralGatewayApp.campaignUser.zipCode' | translate }}</span> <span
                                style="color: red !important;">*</span>
                            </label>
                            <input type="text" class="form-control col-md-9 col-xs-12" maxlength="6" name="zipCode" id="field_zipCode"
                                [(ngModel)]="campaignUser.zipCode" required (keypress)="keyPress($event)"/>
                            

                        </div>
                        <div class="form-group row">
                            <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_phone">
                                <span>{{ 'muferralGatewayApp.campaignUser.phone' | translate }}</span> 
                            </label>
                            <select class="browser-default select-dropdown w-100 form-control col-md-2 col-xs-2" style="height: 45px;"
                                id="field_country" name="country" [(ngModel)]="campaignUser.countryCode">
                                <option value="">Select</option>
                                <option [value]="countryOption.code"
                                    *ngFor="let countryOption of countries;">
                                    +{{countryOption.code}}
                                </option>
                            </select>
                            <input type="text" class="form-control col-md-7 col-xs-12" name="phone" id="field_phone"
                                [(ngModel)]="campaignUser.phone" maxlength=20  (keypress)="keyPress($event)" />
                                <div class="col-md-9 col-xs-12 offset-md-3" style="padding-left:0;"><span
                                    class="public-optional-text">{{ 'muferralGatewayApp.campaignUser.optional' | translate }}</span>
                            </div>
                        </div>
                        <div class="form-group row pulbic-line"><hr></div>
                        <div class="form-group row">
                            <label class="form-control-label col-sm-12 no-padding" for="type"
                            >
                                <span>{{ 'muferralGatewayApp.campaignUser.typeinfomessage' | translate }}</span>
                            </label>
                            <br>
                        <label class="form-control-label col-sm-12 no-padding" for="type">
                            <span>{{ 'muferralGatewayApp.campaignUser.typemessage' | translate }}</span>
                        </label>
                     </div>
                     <div class="form-group row" style="margin-top: 20px;">
                        <div class="form-group col-12 col-lg-6 serviceList">
                            <label class="form-control-label col-md-6 col-xs-12 no-padding" for="field_item">
                                <span>{{ 'muferralGatewayApp.campaignUser.items' | translate }}:</span></label>
                            <ul>
                                <li>{{ 'muferralGatewayApp.campaignUser.item1' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item2' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item3' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item4' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item5' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item6' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item7' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item8' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item9' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.item10' | translate }}</li>
                                <!-- <li>{{ 'muferralGatewayApp.campaignUser.item11' | translate }}</li> -->
                            </ul>
                        </div>
                        <div class="form-group col-12 col-lg-6 serviceList">
                            <label class="form-control-label col-md-6 col-xs-12 no-padding" for="field_service">
                                <span>{{ 'muferralGatewayApp.campaignUser.services' | translate }}:</span></label>
                            <ul>
                                <li>{{ 'muferralGatewayApp.campaignUser.service1' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service2' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service3' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service4' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service5' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service6' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service7' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service8' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service9' | translate }}</li>
                                <li>{{ 'muferralGatewayApp.campaignUser.service10' | translate }}</li>
                                <!-- <li>{{ 'muferralGatewayApp.campaignUser.service11' | translate }}</li> -->
                            </ul>
                        </div>
                    </div>


                        <form name="itemServiceForm" role="form" novalidate (ngSubmit)="saveUser()"
                            #itemServiceForm="ngForm">

                            <div class="form-group row">
                                <label class="form-control-label col-md-3 col-xs-12 no-padding" for="type">
                                    <span>{{ 'muferralGatewayApp.campaignUser.type' | translate }}</span> <span
                                    style="color: red !important;">*</span>
                                </label>
                                <div class="form-check form-check-inline pulic-radio-left">
                                    <input class="form-check-input" name="type" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio1" value="item" [(ngModel)]="campaignUser.type"
                                        #radioInput="ngModel" required>
                                    <label class="form-check-label" for="inlineRadio1">{{ 'muferralGatewayApp.campaignUser.item' | translate }}</label>
                                </div>
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" name="type" type="radio" name="inlineRadioOptions"
                                        id="inlineRadio2" value="service" [(ngModel)]="campaignUser.type"
                                        #radioInput="ngModel">
                                    <label class="form-check-label" for="inlineRadio2">{{ 'muferralGatewayApp.campaignUser.service' | translate }}</label>
                                </div>

                            </div>

                            <div class="form-group row">
                                <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_title">
                                    <span>{{ 'muferralGatewayApp.campaignUser.title' | translate }}</span> <span
                                    style="color: red !important;">*</span>
                                </label>
                                <input type="text" class="form-control col-md-9 col-xs-12" name="title" id="field_title"
                                    [(ngModel)]="campaignUser.title" maxlength="120" autocomplete="off" required />
                            </div>

                            <div class="form-group row">
                                <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_description">
                                    <span >{{ 'muferralGatewayApp.campaignUser.description' | translate }}</span>
                                    <span  style="color: red !important;">*</span>
                                </label>
                                <textarea type="text" class="form-control col-md-9 col-xs-12" name="description" [maxlength]="maxChars"
                                    id="field_description" [(ngModel)]="campaignUser.description" required></textarea>
                            </div>

                            <div class="form-group row">
                                <label class="form-control-label col-md-3 col-xs-12 no-padding" for="field_image">
                                    <span>{{ 'muferralGatewayApp.campaignUser.image' | translate }}</span>
                                    <span  style="color: red !important;">*</span></label>
                                <div class="custom-file form-control col-md-9 col-xs-12">
                                    <input type="file" class="custom-file-input" id="image" name="image"
                                        accept="image/x-png,image/gif,image/jpeg" [(ngModel)]="campaignUser.image"
                                        (change)="readFile($event)" required>
                                    <label class="custom-file-label" for="customFile">{{ 'muferralGatewayApp.campaignUser.choose' | translate }}</label>
                                    <label class="custom-file-label1" for="customFile1">{{ 'muferralGatewayApp.campaignUser.browser' | translate }}</label>
                                </div>
                                <label class="form-control-label col-md-3 col-xs-12 no-padding"
                                    for="field_image">{{profileImageFile?.name}}</label>
                            </div>

                            <div class="form-group row">
                                <label class="form-control-label col-md-3 col-xs-12 no-padding"></label>
                                <button  type="button" id="save-entity"
                                    (click)="addItemService()" class="btn btn-primary public-add-btn"
                                    [disabled]="itemServiceForm.form.invalid">
                                    <span>{{ 'muferralGatewayApp.campaignUser.addtype' | translate }}</span>
                                </button>
                            </div>
                        </form>

                       
                        <div class="form-group row public-table-cover">
                            <table class="table table-hover table-bordered">
                                <thead>
                                    <tr>
                                        <th >{{ 'muferralGatewayApp.campaignUser.title' | translate }}</th>
                                        <th >{{ 'muferralGatewayApp.campaignUser.image' | translate }}</th>
                                        <th >{{ 'muferralGatewayApp.campaignUser.type' | translate }}</th>
                                        <th >{{ 'muferralGatewayApp.campaignUser.description' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let doc of servicesItemsArray">
                                        <td>{{doc.title}}</td>
                                        <!-- <td><img  src="{{doc.image}}" class="img-responsive pulbic-table-img" /></td> -->
                                        <td><img src="{{doc.image}}" [ngClass]="doc.type == 'item' ? 'img-responsive pulbic-table-img' : 'img-responsive public-table-img-service'" /></td>
                                        <td>{{doc.type}}</td>
                                        <td>{{doc.description}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group row pulbic-line"><hr></div>
                        <!-- <div class="form-group row no-gap-bottom">
                            <input class="form-check-input" [checked]="true" [(ngModel)]="campaignUser.iKnowInPerson"
                                type="checkbox" name="iKnowInPerson" id="field_iKnowInPerson" />
                            <label class="form-check-label" for="field_iKnowInPerson">
                                <span>I know <b>{{reffererName}}</b></span><span class="c-red">*</span></label>
                        </div> -->
                        <div class="form-group row no-gap-bottom">
                            <input class="form-check-input" [checked]="true" [(ngModel)]="campaignUser.ageOverEighteen"
                                type="checkbox" name="ageOverEighteen" id="field_ageOverEighteen" required />
                            <label class="form-check-label" for="field_ageOverEighteen">
                                <span >{{ 'muferralGatewayApp.campaignUser.ageOverEighteen' | translate }}</span>
                                <span style="color: red !important;">*</span>
                            </label>
                        </div>
                        <div class="form-group row no-gap-bottom">
                            <input class="form-check-input" [checked]="true"
                                [(ngModel)]="campaignUser.termsAndConditionsRead" type="checkbox"
                                name="termsAndConditionsRead" id="field_termsAndConditionsRead" required />
                            <label class="form-check-label" for="field_termsAndConditionsRead">
                                <span >{{ 'muferralGatewayApp.campaignUser.haveread' | translate }}</span>
                                <span style="margin-left: 3px;"><a style="color:#4C87FA" href="#/term-condition" target="_blank"> 
                                    {{ 'muferralGatewayApp.campaignUser.termsAndConditionsRead' | translate }}</a></span>
                               {{ 'muferralGatewayApp.campaignUser.read_the' | translate }}<span  style="color: red !important;">*</span>
                            </label>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center public-submit-btn-cover">
                                <button  type="submit" id="save-entity"
                                    [disabled]="campaignForm.form.invalid || isSaving" class="btn btn-primary public-add-btn">
                                    <!-- <fa-icon [icon]="'save'"></fa-icon>&nbsp; -->
                                    <span >{{ 'muferralGatewayApp.campaignUser.submit' | translate }}</span>
                                </button>
                                <!-- <button style="background-color: gainsboro !important;" type="button" id="cancel-save"
                                    class="btn btn-default" (click)="previousState()">
                                    <fa-icon [icon]="'ban'"></fa-icon>&nbsp;<span
                                        jhiTranslate="entity.action.cancel">Cancel</span>
                                </button> -->

                            </div>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</div>

<!-- <div mdbModal #basicModal="mdbModal" class="modal fade right" tabindex="-1" role="dialog"
    aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close pull-right" aria-label="Close" (click)="basicModal.hide()">
                    <span aria-hidden="true">×</span>
                </button>
                <h4 class="modal-title w-100" id="myModalLabel"
                    jhiTranslate="muferralGatewayApp.campaignUser.zipcodeinuser">Modal title</h4>
            </div>
            <div class="modal-body">
                <table class="table table-hover table-bordered">
                    <thead>
                        <tr>
                            <th jhiTranslate="muferralGatewayApp.campaignUser.zipCode">Zip Code</th>
                            <th jhiTranslate="muferralGatewayApp.campaignUser.noofuser">No of Users</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let zip of zipCodes">
                            <td>{{zip.zipCode}}</td>
                            <td>{{zip.usersCount}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" mdbBtn color="primary" class="waves-light" aria-label="Close"
                    (click)="basicModal.hide()" mdbWavesEffect>Close</button>
            </div>
        </div>
    </div>
</div> -->

<div class="col-md-4 custom-box-width custom-box-width-success" *ngIf="success">
    <div class="card">

        <div class="row justify-content-center card-body">
            <div class="col-md-12 col-xs-12 no-padding-success">
                <p class="text-center" style="font-size: 22px;"><strong>{{ 'muferralGatewayApp.campaignUser.thank_you' | translate }} {{firstName}} {{lastName}} {{ 'muferralGatewayApp.campaignUser.thank_you1' | translate }}</strong></p>
                <p class="text-center"><strong>{{'muferralGatewayApp.campaignUser.you_have1' | translate }}
                     {{reffererName}} {{'muferralGatewayApp.campaignUser.you_have3' | translate }}</strong></p>
                <br>
                <p class="text-center">{{ 'muferralGatewayApp.campaignUser.we_have' | translate }}</p>
                
                <p class="text-center">{{ 'muferralGatewayApp.campaignUser.when_the' | translate }}
                    <b>{{ 'muferralGatewayApp.campaignUser.you_secure_bold' | translate }}</b>{{ 'muferralGatewayApp.campaignUser.until_then' | translate }}</p>
                
                <p class="text-center">{{ 'muferralGatewayApp.campaignUser.below_is' | translate }}</p>
                <fieldset>
               <textarea rows="5" [(ngModel)]="messageNotifNoLogin" value="{{ 'muferralGatewayApp.campaignUser.hi_friend' | translate }}" style="width: 100%;text-align: center;">
                <!-- {{ 'muferralGatewayApp.campaignUser.hi_friend' | translate }} -->
            </textarea>
                </fieldset>
                    <div class="text-center">
                    <button  [cdkCopyToClipboard]="copy(messageNotifNoLogin)" class="btn btn-primary public-add-btn" style="margin-top: 10px !important">
                        <span>{{ 'muferralGatewayApp.campaignUser.copy_invite' | translate }}</span>
                       </button>
                    </div>
                <br><br>
                <!-- <p><strong>Thank you for accepting {{reffererName}}'s invitation. The above link is also sent to your email as your dedicated link to invite your family, friends and
                    fans under your name to join so you get paid in your wallet upon their final sign up and submitting
                    offers once the app is released. You secure your referral program before others along with the
                    Muferral Program benefits for their items and services perpetually. Please make sure the email is
                    not in your bulk folder in case not in your inbox.</strong></p> -->
                    <h6 class="text-center"><strong>{{ 'muferralGatewayApp.campaignUser.we_all' | translate }}</strong></h6>
                    <i class="text-center">{{ 'muferralGatewayApp.campaignUser.remeber_to' | translate }}</i>
            </div>
        </div>
    </div>
</div>


<div class="col-md-4 custom-box-width custom-box-width-success" *ngIf="registerationStatus">
    <div class="card">

        <div class="row justify-content-center card-body" style="padding:106px 6px;">
            <div class="col-md-12 col-xs-12 no-padding-success">
                <p class="text-center" style="font-size: 22px;"><strong>{{ 'muferralGatewayApp.campaignUser.invitation_successfully' | translate }}!</strong></p>
                <p class="text-center" style="font-size: 16px; margin-bottom: 0px;"><strong>{{'muferralGatewayApp.campaignUser.personalized_invitation' | translate }}
                
                {{ 'muferralGatewayApp.campaignUser.remember_to_check' | translate }}
                    </strong></p>
                <!-- <br> -->
                <!-- <p class="text-center"><strong>{{ 'muferralGatewayApp.campaignUser.remember_to_check' | translate }}</strong></p> -->
                
              
               
               
            </div>
        </div>
    </div>
</div>

<!-- <div class="card" *ngIf="registerationStatus">
            
    <div class="row justify-content-center card-body" style="padding:106px 20px;">
        <div class="col-md-10 col-xs-12 no-padding-success">
            <p class="text-center" style="font-size: 22px;"><strong>{{ 'muferralGatewayApp.campaignUser.invitation_successfully' | translate }}</strong></p>
            <p class="text-center"  style="font-size: 18px;"><strong>{{ 'muferralGatewayApp.campaignUser.personalized_invitation' | translate }}</strong></p>
            <br>
            <p class="text-center"><strong>{{ 'muferralGatewayApp.campaignUser.remember_to_check' | translate }}</strong></p>
        </div>
    </div>
</div> -->

<div class="loading-indicator" *ngIf="loading">
    <mat-progress-spinner mode="indeterminate" [diameter]="50" color="primary"></mat-progress-spinner>
</div>
</div>