import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';
import { of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadAwsService {
    constructor() {}

    uploadFile(file, fileName, s3Bucket) {
        const contentType = file.type;
        const bucket = new S3({
            accessKeyId: 'AKIA2ZP7LKFIXO5UXJY2',
            secretAccessKey: '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',
            region: 'us-east-1'
        });
        const params = {
            Bucket: s3Bucket,
            Key: fileName,
            Body: file,
            ACL: 'public-read',
            ContentType: contentType
        };

        return bucket
            .upload(params, function(err, data) {
                if (err) {
                    console.log('There was an error uploading your file: ', err);
                    return of(err);
                }
                console.log('Successfully uploaded file.', data);
                return of(data);
            })
            .promise();
    }
}
