import { Component, OnInit, ViewChild, ElementRef, AfterViewInit,ViewEncapsulation } from '@angular/core';
import { InviteService } from '../invite.service';
import { ToastrService } from 'ngx-toastr'
import { ActivatedRoute } from '@angular/router';
import {Title} from "@angular/platform-browser";
// import {NgbTabsetConfig} from '@ng-bootstrap/ng-bootstrap';
import { NgbTabset } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from '../app.service';
@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['invite.component.scss'],
  providers: [NgbTabset],
  encapsulation: ViewEncapsulation.None
})
export class InviteComponent implements OnInit,AfterViewInit {
    @ViewChild('ctdTabset') ctdTabset;
    test="https://www.youtube.com/embed/GOxstjuzWYk";
  languages: any[];
  isSaving: boolean;
  email: string;
  userFullName:string;
  confirmEmail: string;
  loading: boolean;
  success: boolean;
  name: any;
  TabIndex=2;
  captcha: string;
  condition1: boolean;
  condition2: boolean;
  condition3: boolean;
  senderId: string;
  userName: string;
  uniqueString:string
  isUserNameAvailable: Boolean = false;
  langKeyArray: string[] = [];
  selectedLanguage: string;
  @ViewChild('basicModal') basicModal: any;
  @ViewChild('mainCaptcha') mainCaptcha: ElementRef;
  video_a:boolean=true;
  video_b:boolean=false;
  video_c:boolean=false;
  yt1:any;
  yt2:any;
  yt3:any;
  dataForHeaderLand:any;
  lngSltData:any;
  language_type:any;
//  yt1:any='https://www.youtube.com/embed/KaM3eecxqrk';
//  yt2:'https://www.youtube.com/embed/KaM3eecxqrk?start=170';
//  yt3:'https://www.youtube.com/embed/KaM3eecxqrk?start=213';
//   public tabs: Observable<ITab[]>;
  constructor(public config: NgbTabset,private _service: InviteService, private activatedRoute: ActivatedRoute,private toastr: ToastrService,private titleService:Title,private appService: AppService) {
      this.success = false;
    //   config.justify = 'center';
    //   config.type = 'pills';
    

  }


  ngOnInit() {
    
    // this.dataForHeaderLand=localStorage.getItem('key') ? localStorage.getItem('key') :localStorage.getItem('firstLang');
    // if(localStorage.getItem('key') === null) 
    // {
    // var dtaValue=localStorage.getItem('firstLang');
    // if(dtaValue==='fr')
    // {
    //   this.lngSltData="French";
    // }
    // else if(dtaValue === 'en' || dtaValue === 'en-GB' || dtaValue === 'en-ZA' || dtaValue === 'en-NZ' || dtaValue === 'en-IN' || dtaValue === 'en-CA' || dtaValue === 'en-US' || dtaValue === 'en-AU')
    // {
    //   this.lngSltData="English";
    // }
    // else if(dtaValue === 'es')
    // {
    //   this.lngSltData="Spanish";
    // }
    // else if(dtaValue === 'zh')
    // {
    //   this.lngSltData="Chinese";
    // }
    // else if(dtaValue === 'it')
    // {
    //   this.lngSltData="Italian";
    // }
    // else if(dtaValue === 'ja')
    // {
    //   this.lngSltData="Japanese";
    // }
    // else if(dtaValue === 'de')
    // {
    //   this.lngSltData="German";
    // }
    // else if(dtaValue === 'hi')
    // {
    //   this.lngSltData="Hindi";
    // }
    // else if(dtaValue === 'ko')
    // {
    //   this.lngSltData="Korean";
    // }
    // else if(dtaValue === 'ru')
    // {
    //   this.lngSltData="Russian";
    // }
    // else if(dtaValue === 'ar')
    // {
    //   this.lngSltData="Arabic";
    // }
    // else if(dtaValue === 'pt')
    // {
    //   this.lngSltData="Portuguese";
    // }else{
    //   console.log('on init dasdsadsad');
      
    //   this.lngSltData = "English";
    // }
    // this.dataForHeaderLand=this.lngSltData ? this.lngSltData : this.dataForHeaderLand ;
    // this.youTubeUrl(this.dataForHeaderLand);
    // }else{
    //   // this.lngSltData = "English";
    //   // this.dataForHeaderLand = this.lngSltData ? this.lngSltData : this.dataForHeaderLand;
    //   // this.youTubeUrl(this.dataForHeaderLand);
    // }
    this.youTubeUrl(this.dataForHeaderLand);
      this.uniqueString = this.activatedRoute.snapshot.queryParams.k;
      if(this.uniqueString){
        this.inviteEmail();
      }
    // var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    // (function()
    
    // { var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0]; s1.async=true; s1.src='https://embed.tawk.to/5e56b866a89cda5a18881827/default'; s1.charset='UTF-8'; s1.setAttribute('crossorigin','*'); s0.parentNode.insertBefore(s1,s0); }
    // )();
//     var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
// (function(){
// var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
// s1.async=true;
// s1.src='https://embed.tawk.to/5eda4a7a9e5f6944228ff6c8/default';
// s1.charset='UTF-8';
// s1.setAttribute('crossorigin','*');
// s0.parentNode.insertBefore(s1,s0);
// })();
    // this.config.select(tabs[1].id.toString());
    // this.tabs.setActiveTab(2);
    // this.config.select('muferral_reward_program');
    
      this.activatedRoute.queryParams.subscribe((params: any) => {
          if (typeof params.userId !== undefined) {
              this.senderId = atob(params.userId.split('=')[0]);
          }
          if (typeof params.userName !== undefined) {
              this.userName = atob(params.userName.split('=')[0]);
          }

          if (this.userName !== undefined) {
              this.isUserNameAvailable = true;
              this.titleService.setTitle('Muferral | ' +  this.userName +'’s invitation');
          }
      });
    // this.getDropdwonLan();
    let langName = localStorage.getItem('firstLang');
    if(langName){
      let data = this.changeKeyTolang(langName);
      this.language_type = data;
      this.youTubeUrl(data);
    } else{
      let data = this.changeKeyTolang("en");
      this.language_type = data;
      this.youTubeUrl(data);
    }
    let langKey = localStorage.getItem('key');
    if(langKey){
      this.language_type = this.changeLangToKey(langKey);
      this.youTubeUrl(langKey);
    }
    this.appService.stringSubject.subscribe(
      data => {
        this.language_type = this.changeLangToKey(data);
        this.youTubeUrl(data);
      }
    );
  }

  changeLangToKey(language){
    let language_type = 'en';
    if (language === 'English') {
      language_type = 'en';
    } else if (language === 'French') {
      language_type = 'fr';
    } else if (language === 'Spanish') {
      language_type = 'es';
    } else if (language === 'Chinese') {
      language_type = 'zh';
    } else if (language === 'Italian') {
      language_type = 'it';
    } else if (language === 'Japanese') {
      language_type = 'ja';
    } else if (language === 'German') {
      language_type = 'de';
    } else if (language === 'Hindi') {
      language_type = 'hi';
    } else if (language === 'Korean') {
      language_type = 'ko';
    } else if (language === 'Russian') {
      language_type = 'ru';
    } else if (language === 'Arabic') {
      language_type = 'ar';
    } else if (language === 'Portuguese') {
      language_type = 'pt';
    } else {
      language_type = 'en';
    }
    return language_type;
  }

  changeKeyTolang(data){
    let lang = "English";
    if(data==='fr')
    {
      lang="French";
    }
    else if(data === 'en' || data === 'en-GB' || data === 'en-ZA' || data === 'en-NZ' || data === 'en-IN' || data === 'en-CA' || data === 'en-US' || data === 'en-AU')
    {
      lang="English";
    }
    else if(data === 'es')
    {
      lang="Spanish";
    }
    else if(data === 'zh')
    {
      lang="Chinese";
    }
    else if(data === 'it')
    {
      lang="Italian";
    }
    else if(data === 'ja')
    {
      lang="Japanese";
    }
    else if(data === 'de')
    {
      lang="German";
    }
    else if(data === 'hi')
    {
      lang="Hindi";
    }
    else if(data === 'ko')
    {
      lang="Korean";
    }
    else if(data === 'ru')
    {
      lang="Russian";
    }
    else if(data === 'ar')
    {
      lang="Arabic";
    }
    else if(data === 'pt')
    {
      lang="Portuguese";
    }else{
      lang = "English";
    }
    return lang;
  }

  // changeLanInfo(){
  //   if (this.lngSltData === 'English') {
  //     this.language_type = 'en';
  //   } else if (this.lngSltData === 'French') {
  //     this.language_type = 'fr';
  //   } else if (this.lngSltData === 'Spanish') {
  //     this.language_type = 'es';
  //   } else if (this.lngSltData === 'Chinese') {
  //     this.language_type = 'zh';
  //   } else if (this.lngSltData === 'Italian') {
  //     this.language_type = 'it';
  //   } else if (this.lngSltData === 'Japanese') {
  //     this.language_type = 'ja';
  //   } else if (this.lngSltData === 'German') {
  //     this.language_type = 'de';
  //   } else if (this.lngSltData === 'Hindi') {
  //     this.language_type = 'hi';
  //   } else if (this.lngSltData === 'Korean') {
  //     this.language_type = 'ko';
  //   } else if (this.lngSltData === 'Russian') {
  //     this.language_type = 'ru';
  //   } else if (this.lngSltData === 'Arabic') {
  //     this.language_type = 'ar';
  //   } else if (this.lngSltData === 'Portuguese') {
  //     this.language_type = 'pt';
  //   } else {
  //     this.language_type = 'en';
  //   }

  //   if (!this.lngSltData) {
  //     if (this.dataForHeaderLand === 'English') {
  //       this.language_type = 'en';
  //     } else if (this.dataForHeaderLand === 'French') {
  //       this.language_type = 'fr';
  //     } else if (this.dataForHeaderLand === 'Spanish') {
  //       this.language_type = 'es';
  //     } else if (this.dataForHeaderLand === 'Chinese') {
  //       this.language_type = 'zh';
  //     } else if (this.dataForHeaderLand === 'Italian') {
  //       this.language_type = 'it';
  //     } else if (this.dataForHeaderLand === 'Japanese') {
  //       this.language_type = 'ja';
  //     } else if (this.dataForHeaderLand === 'German') {
  //       this.language_type = 'de';
  //     } else if (this.dataForHeaderLand === 'Hindi') {
  //       this.language_type = 'hi';
  //     } else if (this.dataForHeaderLand === 'Korean') {
  //       this.language_type = 'ko';
  //     } else if (this.dataForHeaderLand === 'Russian') {
  //       this.language_type = 'ru';
  //     } else if (this.dataForHeaderLand === 'Arabic') {
  //       this.language_type = 'ar';
  //     } else if (this.dataForHeaderLand === 'Portuguese') {
  //       this.language_type = 'pt';
  //     } else {
  //       this.language_type = 'en';
  //     }
  //   }
  // }

  invite() {
      const request = {
          receiverEmail: this.email,
          senderId: this.senderId,
          languageType : this.language_type
      };
      if(this.email !==this.confirmEmail){
        this.toastr.info('Confirm Email did not match');
        return;
      }
      if (this.validateCaptcha() === false) {
          this.toastr.info('Captcha not matched');
          return;
      }
      this.loading = true;
      this._service.inviteUser(request).subscribe(
          resp => {
              if (resp) {
                  this.toastr.success('Invitation Sent');
                  this.success = true;
                  this.loading = false;
              }
          },
          err => {
              this.success = false;
              this.loading = false;
              this.toastr.error(err.error.errorMessage);
          }
      );
  }

  ngAfterViewInit() {
      this.generateCaptcha();
      this.switchNgBTab('campaign');
  }
  

   switchNgBTab(id: string) {
     this.ctdTabset.select(id);
   }
  generateCaptcha() {
      const alpha = new Array(
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z',
          'a',
          'b',
          'c',
          'd',
          'e',
          'f',
          'g',
          'h',
          'i',
          'j',
          'k',
          'l',
          'm',
          'n',
          'o',
          'p',
          'q',
          'r',
          's',
          't',
          'u',
          'v',
          'w',
          'x',
          'y',
          'z',
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9'
      );
      let a, b, c, d, e, f;

      for (let i = 0; i < 6; i++) {
          a = alpha[Math.floor(Math.random() * alpha.length)];
          b = alpha[Math.floor(Math.random() * alpha.length)];
          c = alpha[Math.floor(Math.random() * alpha.length)];
          d = alpha[Math.floor(Math.random() * alpha.length)];
          e = alpha[Math.floor(Math.random() * alpha.length)];
          f = alpha[Math.floor(Math.random() * alpha.length)];
      }
      const code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' ' + f;
      this.mainCaptcha.nativeElement.innerHTML = code;
  }

  validateCaptcha() {
      const string1 = this.removeSpaces(this.mainCaptcha.nativeElement.innerHTML);
      if (string1 === this.captcha) {
          return true;
      } else {
          return false;
      }
  }

  removeSpaces(string) {
      return string.split(' ').join('');
  }
  video(e:any){
    if(e==='a'){
        console.log("enter in a");
      this.video_a=true;
      this.video_b=false;
      this.video_c=false;
    }
    else if(e==='b'){
        console.log("enter in b");
        this.video_a=false;
        this.video_b=true;
        this.video_c=false;
      }
      else if(e==='c'){
        console.log("enter in c");
        this.video_a=false;
        this.video_b=false;
        this.video_c=true;
      }
  }
  youTubeUrl(e){
    if(e==='French')
    {
        this.yt1="https://www.youtube.com/embed/29KUYAZHLfA";										
        this.yt2="https://www.youtube.com/embed/29KUYAZHLfA?start=193";
        this.yt3="https://www.youtube.com/embed/29KUYAZHLfA?start=236";
    }
    else if(e ==='English')
    {
        this.yt1="https://www.youtube.com/embed/o7pBkmqdQKI";			
        this.yt2="https://www.youtube.com/embed/o7pBkmqdQKI?start=193";
        this.yt3="https://www.youtube.com/embed/o7pBkmqdQKI?start=235";
    }
    else if(e === 'Spanish')
    {
        this.yt1="https://www.youtube.com/embed/-kFwx4P-pyI";																								
        this.yt2="https://www.youtube.com/embed/-kFwx4P-pyI?start=194";																							
        this.yt3="https://www.youtube.com/embed/-kFwx4P-pyI?start=236";
    }
    else if(e === 'Chinese')
    {
        this.yt1="https://www.youtube.com/embed/-JM6WV0_FzfM";																				
        this.yt2="https://www.youtube.com/embed/-JM6WV0_FzfM?start=193";																							
        this.yt3="https://www.youtube.com/embed/-JM6WV0_FzfM?start=236";
    }
    else if(e === 'Italian')
    {
        this.yt1="https://www.youtube.com/embed/5XceAUPB2u0";																								
        this.yt2="https://www.youtube.com/embed/5XceAUPB2u0?start=207";																									
        this.yt3="https://www.youtube.com/embed/5XceAUPB2u0?start=253";
    }
    else if(e === 'Japanese')
    {
        this.yt1="https://www.youtube.com/embed/vx78Ijl4KbE";																								
        this.yt2="https://www.youtube.com/embed/vx78Ijl4KbE?start=194";																								
        this.yt3="https://www.youtube.com/embed/vx78Ijl4KbE?start=236";
    }
    else if(e === 'German')
    {
        this.yt1="https://www.youtube.com/embed/D3Yw27Q2uY4";																							
        this.yt2="https://www.youtube.com/embed/D3Yw27Q2uY4?start=200";																									
        this.yt3="https://www.youtube.com/embed/D3Yw27Q2uY4?start=245";
      
    }
    else if(e === 'Hindi')
    {
        this.yt1="https://www.youtube.com/embed/n28aTpStnbE";																							
        this.yt2="https://www.youtube.com/embed/n28aTpStnbE?start=193";																									
        this.yt3="https://www.youtube.com/embed/n28aTpStnbE?start=235";
    }
    else if(e === 'Korean')
    {
        this.yt1="https://www.youtube.com/embed/gmHRP_Ef1kI";																							
        this.yt2="https://www.youtube.com/embed/gmHRP_Ef1kI?start=194";																									
        this.yt3="https://www.youtube.com/embed/gmHRP_Ef1kI?start=236";
    }
    else if(e === 'Russian')
    {
        this.yt1="https://www.youtube.com/embed/_wypeYYq4Gs";																							
        this.yt2="https://www.youtube.com/embed/_wypeYYq4Gs?start=217";																									
        this.yt3="https://www.youtube.com/embed/_wypeYYq4Gs?start=258";
    }
    else if(e === 'Arabic')
    {
        this.yt1="https://www.youtube.com/embed/Eg2lZx4dFhU";																								
        this.yt2="https://www.youtube.com/embed/Eg2lZx4dFhU?start=193";																									
        this.yt3="https://www.youtube.com/embed/Eg2lZx4dFhU?start=235";
    }
    else if(e === 'Portuguese')
    {
        this.yt1="https://www.youtube.com/embed/K9OJEcUQKOg";																								
        this.yt2="https://www.youtube.com/embed/K9OJEcUQKOg?start=209";																								
        this.yt3="https://www.youtube.com/embed/K9OJEcUQKOg?start=256";
    }
    // this.yt1='https://www.youtube.com/embed/KaM3eecxqrk';
    //  this.yt2='https://www.youtube.com/embed/KaM3eecxqrk?start=170';
    //  this.yt3='https://www.youtube.com/embed/KaM3eecxqrk?start=213';
  }


  inviteEmail() {
    this.loading = true;
    this._service.inviterEmail(this.uniqueString).subscribe(
        resp => {
            if (resp) {
                // this.toastr.success('Invitation Sent');
                // this.success = true;
                this.userName = resp.body.userFullName;
                this.senderId = resp.body.userId
              if (this.userName !== undefined) {
              this.isUserNameAvailable = true;
              this.titleService.setTitle('Muferral | ' +  this.userName +'’s invitation');
             }
                this.loading = false;
            }
        },
        err => {
            this.success = false;
            this.loading = false;
            this.toastr.error(err.error.errorMessage);
        }
    );
}
}

