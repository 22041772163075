<!-- <div class="col-md-12 col-xs-12 mb-3">
    <div class="view">
        <img src="../../../../content/images/logo.png" style="height:100px;width:300px;margin: 0 auto;"
            class="card-img-top" alt="photo">
    </div>
</div> -->

<div class="col-md-4 custom-box-width custom-box-width-success">
    <div class="card col-md-12 public-ref-card-body">
        <div class="row">
            <div class="col-md-12">
                <h5 id="page-heading" class="public-ref-heading">
                    <span>{{ 'muferralGatewayApp.campaignUser.ziplistData' | translate }}</span>
                </h5>
            </div>
        </div>
        <div class="row justify-content-center card-body" style="margin-bottom:70px">
            

            <div class="loading-indicator" *ngIf="loading">
                <mat-progress-spinner mode="indeterminate" [diameter]="50" color="primary"></mat-progress-spinner>
            </div>

            <div class="table-responsive" *ngIf="campaignZipCodes">
                <table class="table table-striped table-bordered invite-zip-code-table">
                    <thead>
                        <tr>
                            <th style="width:20%"><span>{{ 'muferralGatewayApp.campaignUser.country' | translate }}</span>
                            </th>
                          
                            <th style="width:30%"><span>{{ 'muferralGatewayApp.campaignUser.commission' | translate }}</span>
                            </th>
                            <th style="width:20%"><span>{{ 'muferralGatewayApp.campaignUser.ceilingLimit' | translate }}</span>
                            </th>
                           


                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let zip of campaignZipCodes ;let i = index">
                            <td>{{zip.name}}</td>
                            <td>{{zip.referEarnAmount}}{{ ' ' + zip?.currencyCode}}</td>
                            <td>{{zip.ceilingLimit}}{{ ' ' + zip?.currencyCode}}</td>

                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div class="invite-zip-code-pagination">
                <div *ngIf="campaignZipCodes && campaignZipCodes.length" class="row justify-content-center align-items-center">
                    <div class="col-md-6 invite-show-page-text">
                        <jhi-item-count [page]="page" [total]="queryCount" [maxSize]="5" [itemsPerPage]="itemsPerPage">
                        </jhi-item-count>
                    </div>
                    <div class="col-md-6 text-right">
                        <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [pageSize]="itemsPerPage"
                            [maxSize]="5" [rotate]="true" [boundaryLinks]="true" (pageChange)="loadPage(page)">
                        </ngb-pagination>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>