import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AboutComponent} from './about/about.component';
import {ContactComponent} from './contact/contact.component';
import {BlogComponent} from './blog/blog.component';
import {SubmitComponent} from './submit/submit.component';
import {InviteComponent} from './invite/invite.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {PublicRefferalComponent} from './public-refferal/public-refferal.component';
import {UserRefferalComponent} from './user-refferal/user-refferal.component';
import {InviteZipCodeComponent} from './invite-zip-code/invite-zip-code.component';
import {TermConditionComponent} from './term-condition/term-condition.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {FaqComponent} from './faq/faq.component';
import { ContentsComponent } from './contents/contents.component';
import {ReferComponent} from './refer/refer.component';
import {emailSettingComponent} from './email-setting/email-setting.component';
const routes: Routes = [
  {
    path:"invite", component:InviteComponent,
    data: {
      title: 'Muferral | Campaign'
  }
  
  },
  {
    path:"about", component:AboutComponent,
    data: {
      title: 'Muferral | About'
  }
  },
 
  {
    path:"contact", component:ContactComponent,
    data: {
      title: 'Muferral | Contact'
  }
  },
  {
    path:"blog", component:BlogComponent,
    data: {
      title: 'Muferral | Blog'
  }
  },
  {
    path:"submit", component:SubmitComponent,
    data: {
      title: 'Muferral | Submit Your Videos'
  }
  },
  {
    path:"invite", component:InviteComponent,
    data: {
      title: 'Muferral | Campaign'
  }
  },
  {
    path:"term-condition",component:TermConditionComponent,
    data: {
      title: 'Muferral | Terms & Condition'
  }
  },
  {
    path:"private-policy",component:PrivacyPolicyComponent,
    data: {
      title: 'Muferral | Privacy Policy'
  }
  },
  {
    path:"commission-chart", component:InviteZipCodeComponent
  },
  {
    path:'r/:k', component:PublicRefferalComponent,
    data: {
      title: 'Muferral | Public Refferal'
  }
  },
  {
    path:"faq",component:FaqComponent,
    data: {
      title: 'Muferral | FAQ'
  }
  },
  {
    path:'u/:referrerData', component:UserRefferalComponent,
    data: {
      title: 'Muferral | User Refferal'
  }
  },
  {
    path:"content/:title/:key",component:ContentsComponent,
    data: {
      title: 'Muferral | Content'
  }
  },
  {
    path:"content/:title",component:ContentsComponent,
    data: {
      title: 'Muferral | Content'
  }
  },
  {
    path:"setting/:id", component:emailSettingComponent,
    data: {
      title: 'Muferral | Email-Setting'
  }
  },
 
 
  {path: '', redirectTo: '/invite', pathMatch: 'full'},
 
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,  { initialNavigation: true } )],
  exports: [RouterModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}]
})
export class AppRoutingModule { }
