
<div class="submit-video common-main">
	<div class="container">
<h1 style="color: #000;text-align: center;margin-bottom: 50px;">{{ 'muferralGatewayApp.campaignUser.submit_video' | translate }}</h1>
<span [innerHtml]="dataLng"></span>
<!-- <div class="row"> -->
	<!-- <div class="col-md-6 col-xs-12">
		<img src="../../assets/images/about.jpg" class="img-fluid">
	</div> -->
	<!-- <div class="col-md-12 col-xs-12">
		<p>{{ 'muferralGatewayApp.campaignUser.submit_video1' | translate }}</p>
		<h5><strong>{{ 'muferralGatewayApp.campaignUser.submit_video2' | translate }}</strong></h5>
		<ol>
			<li>{{ 'muferralGatewayApp.campaignUser.submit_video3' | translate }}</li>
			<li>{{ 'muferralGatewayApp.campaignUser.submit_video4' | translate }}</li>
				<ul>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video5' | translate }}</li>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video6' | translate }}</li>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video7' | translate }}</li>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video8' | translate }}</li>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video9' | translate }}</li>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video10' | translate }}</li>
					<li>{{ 'muferralGatewayApp.campaignUser.submit_video11' | translate }}</li>
				</ul>
			 <li>{{ 'muferralGatewayApp.campaignUser.submit_video12' | translate }}</li>
			</ol>
			<i>{{ 'muferralGatewayApp.campaignUser.submit_video13' | translate }}</i>
	</div>
	<br>
	<div class="col-md-12 col-xs-12" style="margin-top: 10px;">
		<h5 class="text-center"><strong>{{ 'muferralGatewayApp.campaignUser.submit_video14' | translate }}</strong></h5>
		<h5  class="text-center"><strong>$30 - $300!</strong></h5>
	<br>
	<h5><strong>{{ 'muferralGatewayApp.campaignUser.submit_video15' | translate }}</strong></h5>
	<ul>
		<li>{{ 'muferralGatewayApp.campaignUser.submit_video16' | translate }}</li>
		<li>{{ 'muferralGatewayApp.campaignUser.submit_video18' | translate }}</li>
		<li>{{ 'muferralGatewayApp.campaignUser.submit_video19' | translate }}</li>
		<li>{{ 'muferralGatewayApp.campaignUser.submit_video20' | translate }}</li>
	</ul>
	<br>
	<h5><strong>{{ 'muferralGatewayApp.campaignUser.submit_video21' | translate }}</strong></h5>
     <p>{{ 'muferralGatewayApp.campaignUser.submit_video22' | translate }}</p>
	<p>{{ 'muferralGatewayApp.campaignUser.submit_video23' | translate }}</p>
	<p>{{ 'muferralGatewayApp.campaignUser.submit_video24' | translate }}</p>
	<p><strong>{{ 'muferralGatewayApp.campaignUser.submit_video25' | translate }}</strong></p>
	<p>{{ 'muferralGatewayApp.campaignUser.submit_video26' | translate }}</p>
	<p>{{ 'muferralGatewayApp.campaignUser.submit_video27' | translate }}</p>
	
	</div>
</div> -->
</div>
</div>