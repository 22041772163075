
<div class="col-md-12" style="padding-top: 35px;">
	<div class="card col-md-12 public-ref-card-body">
		<div class="row">
			<div class="col-md-12">
				<h1 style="color: #000;text-align: center;margin-bottom: 15px;">{{
					'muferralGatewayApp.campaignUser.emailSettingHead' | translate }}</h1>
				<h6 style="color: #000;text-align: center;margin-bottom: 34px;">{{
					'muferralGatewayApp.campaignUser.emailSettingSubHead' | translate }}</h6>
			</div>
		</div>
		<div class="row justify-content-center card-body">
			<div class="loading-indicator" *ngIf="loading">
				<mat-progress-spinner mode="indeterminate" [diameter]="50" color="primary"></mat-progress-spinner>
			</div>
			<div class="col-sm-12" style="color: #000;text-align: center;margin-bottom: 15px;">
				<span style="color: #000;text-align: center;margin-bottom: 15px; vertical-align: text-bottom;
				padding-right: 21px;">{{ 'muferralGatewayApp.campaignUser.campaignEmail' | translate }}</span>
				<label class="switch">
					<input type="checkbox" [(ngModel)]="campaignUser.email" checked>
					<span class="slider1 round"></span>
				</label>
			</div>
			<div class="row">
				<div class="col-12 text-center public-submit-btn-cover">
					<button type="submit" id="save-entity" class="btn btn-primary public-add-btn1"
						(click)="emailSetting()">
						<!-- <fa-icon [icon]="'save'"></fa-icon>&nbsp; -->
						<span>{{ 'muferralGatewayApp.campaignUser.submit' | translate }}</span>
					</button>
				</div>
			</div>
		</div>
	</div>
</div>