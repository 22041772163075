<nav class="navbar navbar-expand-lg navbar-light invite-header">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/logo.png" style="height:45px" class="c-pointer" alt="photo">
    </a>
     <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto ">
        <li class="nav-item ">
          <a class="nav-link" routerLinkActive="active" routerLink="/invite" (click)="ToggleNavBar()">{{
            'muferralGatewayApp.campaignUser.Home' | translate }} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/about" (click)="ToggleNavBar()">{{
            'muferralGatewayApp.campaignUser.about' | translate }} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/blog" (click)="ToggleNavBar()">{{
            'muferralGatewayApp.campaignUser.blog' | translate }} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/faq" (click)="ToggleNavBar()">{{
            'muferralGatewayApp.campaignUser.faq' | translate }}</a>
        </li>
        <li class="nav-item ">
          <a class="nav-link" routerLinkActive="active" routerLink="/submit" (click)="ToggleNavBar()">{{
            'muferralGatewayApp.campaignUser.submit-your-videos' | translate }} </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/contact" (click)="ToggleNavBar()">{{
            'muferralGatewayApp.campaignUser.contact' | translate }} </a>
        </li>
       
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <ul class="navbar-nav right-menu">
          <!-- <li>
            <select class="browser-default select-dropdown form-control" #langSelect
            (change)="changelanguage(langSelect.value)">
            <option *ngFor="let lang of langList; let i= index" [value]="lang.imageSrc"
              > <img src={{lang.imageSrc}} /></option>
          </select>
          </li> -->
          <li class="nav-item ">
            <div class="form-group" style="display: none;">
              <!-- <label  jhiTranslate="userManagement.langKey">Lang Key</label> -->
              <select class="browser-default select-dropdown form-control" #langSelect
                (change)="changelanguage(langSelect.value)">
                <!-- <option *ngFor="let language of languages" [selected]="language.langKey=='en'" [value]="language.langKey">
                            {{language.langName}}</option> -->

                <option *ngFor="let lang of translate.getLangs(); let i= index" [value]="lang"
                  [selected]="lang === translate.currentLang">{{ lang }}</option>
              </select>
            </div>
            <mat-select-country 
                    class="selectCountry"
                    appearance="outline"
                    [countries]="Country"
                    [value]="defaultValue"
                    (onCountrySelected)="onCountrySelected($event)" 
                    (keydown)="keydown($event)"
                    onfocus="blur();">
            </mat-select-country>

          </li>
        </ul>

      </form>
    </div>
  </div>
</nav>